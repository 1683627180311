<template>
  <header>
    <nav>
      <router-link
        id="brand-logo"
        :to="{ name: 'Home' }"
      >
        <img
          src="../assets/JingoLogo.png"
          alt="回首頁"
        >
      </router-link>
      <div
        id="products-link"
        @click="openProductsModal"
      >
        探索產品
      </div>
      <div
        v-if="promotionPages.length > 0"
        id="products-link"
        @click="openPromotionsModal"
      >
        優惠活動
      </div>
      <router-link
        to="/products?products_grid[formats][]=講堂票券"
        class="not-change-color"
      >
        金革講堂
      </router-link>
      <router-link :to="{name: 'Articles'}">
        音樂觀點
      </router-link>
      <router-link :to="{name: 'MusicPapers'}">
        音樂報
      </router-link>
      <router-link :to="{name: 'Public'}">
        企業公播
      </router-link>
    </nav>
    <div id="header-actions">
      <form
        id="header-search-form"
        @submit.prevent.stop="submitSearch"
        @keydown="enteringSearchTerm"
      >
        <transition
          name="custom-classes-transition"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
        >
          <div
            v-if="openSearch && showSearchTooltip"
            class="tooltip"
          >
            請輸入至少兩個關鍵字
          </div>
        </transition>
        <transition
          name="custom-classes-transition"
          enter-active-class="animate__animated animate__slideInLeft"
          leave-active-class="animate__animated animate__fadeOutLeft"
        >
          <input
            v-if="openSearch"
            v-model="searchTerms"
            type="search"
            placeholder="請輸入關鍵字再點放大鏡"
          >
        </transition>
        <button id="search-btn">
          <InlineSvg :src="require('../assets/icon-search.svg')" />
        </button>
      </form>
      <button
        v-if="currentUser"
        @click.prevent.stop="$emit('open-menu')"
      >
        Hi, {{ currentUser.name }}
      </button>
      <button
        v-else
        @click.prevent="showLogin"
      >
        登入 / 註冊
      </button>
      <button
        id="cart"
        @click.stop.prevent="showCart"
      >
        <InlineSvg :src="require('../assets/cart.svg')" />
        <span
          v-if="cartQuantity > 0"
          class="cart-qty"
        >{{ cartQuantity }}</span>
      </button>
    </div>
  </header>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, inject } from 'vue'
import InlineSvg from "vue-inline-svg";
import { useRouter } from 'vue-router';

export default {
  components: { InlineSvg },
  emits: ['open-menu'],
  setup() {
    const store = useStore()
    const showLogin = () => store.commit('openLogin')

    const currentUser = computed(() => store.getters.currentUser)

    let openSearch = ref(false)
    let searchTerms = ref('')
    const router = useRouter()
    let showSearchTooltip = ref(false)
    const submitSearch = () => {
      console.log(searchTerms.value.length)
      if(!openSearch.value) {
        openSearch.value = true
      } else if(searchTerms.value === '') {
        openSearch.value = false
        showSearchTooltip.value = false
      } else if(searchTerms.value.length < 2) {
        showSearchTooltip.value = true
      } else {
        router.push({
          name: 'Search',
          query: {
            q: searchTerms.value
          }
        })
        searchTerms.value = ''
        openSearch.value = false
        showSearchTooltip.value = false
      }
    }
    const enteringSearchTerm = () => {
      showSearchTooltip.value = false
    }

    const cartQuantity = computed(() => store.getters.cartQuantity)
    const showCart = () => {
      if(!store.getters.isLoggedIn) {
        store.commit('openLogin')
        return
      }
      store.commit('openCart')
    }

    const openProductsModal = () => {
      store.commit('openModal', 'showProductTags')
    }

    const openPromotionsModal = () => {
      store.commit('openModal', 'showPromotions')
    }

    const promotionPages = inject('promotionPages')

    return {
      currentUser,
      showLogin,
      openSearch,
      searchTerms,
      submitSearch,
      showSearchTooltip,
      enteringSearchTerm,
      cartQuantity,
      showCart,
      openProductsModal,
      openPromotionsModal,
      promotionPages
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: $background-color;
  height: 85px;
  border-top: 5px solid #24554C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 40px;
  box-shadow: 0px 4px 4px rgba(90, 190, 134, 0.15);

  #products-link, a {
    cursor: pointer;
    margin-right: 48px;
    color: $dark-green;

    &:hover, &.router-link-exact-active {
      color: $light-green;
    }

    &.not-change-color {
      color: $dark-green;
    }
  }
}

nav {
  display: flex;
  align-items: center;

  #brand-logo {
    margin-right: 50px;
    img {
      width: 150px;
    }
  }
}

#header-actions {
  display: flex;
  align-items: center;

  a {
    margin-right: 25px;
    cursor: pointer;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    color: $dark-green;
    margin-right: 25px;
    font-size: 1rem;

    &:hover {
      color: $light-green;
    }
  }
}

:deep(#search-btn) {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;
  svg {
    width: 18px;
  }

  &:hover {
    svg * {
      stroke: $light-green;
    }
  }
}

:deep(#cart) {
  position: relative;

  svg {
    width: 18px;
  }

  .cart-qty {
    position: absolute;
    top: -15px;
    right: -15px;
    color: white;
    background: $dark-orange;
    font-size: 14px;
    @include flex-center();
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  &:hover {
    svg path {
      fill: $light-green;
    }
  }
}

#header-search-form{
  display: flex;
  margin-right: 10px;
  input {
    width: 200px;
    border: none;
    border-bottom: 2px solid $dark-green;
    padding: 10px;
    background: transparent;
  }
}

/* Tooltip container */
.tooltip {
  width: 200px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: 70px;
}
</style>