import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadcrumb: '首頁'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      breadcrumb: '關於我們'
    }
  },
  {
    path: '/products/:id',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
    meta: {
      breadcrumb: '商品'
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
    meta: {
      breadcrumb: '探索產品'
    }
  },
  {
    path: '/ordsums/new',
    name: 'NewOrdsum',
    component: () => import(/* webpackChunkName: "new_ordsum" */ '../views/NewOrdsum.vue'),
    meta: {
      breadcrumb: '新訂單',
      requiresAuth: true
    }
  },
  {
    path: '/promotions/:slug',
    name: 'Promotion',
    component: () => import(/* webpackChunkName: "promotion" */ '../views/Promotion.vue'),
    meta: {
      breadcrumb: '優惠活動',
    }
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "articles" */ '../views/Articles.vue'),
    meta: {
      breadcrumb: '音樂觀點'
    }
  },
  {
    path: '/articles/:slug',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
    meta: {
      breadcrumb: '音樂觀點文章'
    }
  },
  {
    path: '/authors/:id',
    name: 'Author',
    component: () => import(/* webpackChunkName: "author" */ '../views/Author.vue'),
    meta: {
      breadcrumb: '音樂觀點筆者'
    }
  },
  {
    path: '/music_papers/:id',
    name: 'MusicPaper',
    component: () => import(/* webpackChunkName: "music_paper" */ '../views/MusicPaper.vue'),
    meta: {
      breadcrumb: '音樂報'
    }
  },
  {
    path: '/music_papers',
    name: 'MusicPapers',
    component: () => import(/* webpackChunkName: "music_papers" */ '../views/MusicPapers.vue'),
    meta: {
      breadcrumb: '音樂報'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: {
      breadcrumb: '搜尋結果'
    }
  },
  {
    path: '/public',
    name: 'Public',
    component: () => import(/* webpackChunkName: "public" */ '../views/Public.vue'),
    meta: {
      breadcrumb: '企業公播'
    }
  },
  {
    path: '/cooperate',
    name: 'Cooperate',
    component: () => import(/* webpackChunkName: "cooperate" */ '../views/Cooperate.vue'),
    meta: {
      breadcrumb: '企業合作'
    }
  },
  {
    path: '/terms_and_statements',
    name: 'TermsAndStatements',
    component: () => import(/* webpackChunkName: "terms_and_statements" */ '../views/TermsAndStatements.vue'),
    meta: {
      breadcrumb: '條款與聲明'
    }
  },
  {
    path: '/orders_faq',
    name: 'OrdersFaq',
    component: () => import(/* webpackChunkName: "orders_faq" */ '../views/OrdersFaq.vue'),
    meta: {
      breadcrumb: '購物需知'
    }
  },
  {
    path: '/member_faq',
    name: 'MemberFaq',
    component: () => import(/* webpackChunkName: "member_faq" */ '../views/MemberFaq.vue'),
    meta: {
      breadcrumb: '會員需知'
    }
  },
  {
    path: '/member_center',
    name: 'MemberCenter',
    component: () => import(/* webpackChunkName: "member_center" */ '../views/MemberCenter.vue'),
    meta: {
      breadcrumb: '會員中心',
      requiresAuth: true
    }
  },
  {
    path: '/member_center/overview',
    name: 'MemberCenterOverview',
    component: () => import(/* webpackChunkName: "member_center_overview" */ '../views/MemberCenterOverview.vue'),
    meta: {
      breadcrumb: '帳戶總覽',
      requiresAuth: true
    }
  },
  {
    path: '/member_center/orders',
    name: 'MemberCenterOrders',
    component: () => import(/* webpackChunkName: "member_center_orders" */ '../views/MemberCenterOrders.vue'),
    meta: {
      breadcrumb: '記單紀錄',
      requiresAuth: true
    }
  },
  {
    path: '/member_center/settings',
    name: 'MemberCenterSettings',
    component: () => import(/* webpackChunkName: "member_center_settings" */ '../views/MemberCenterSettings.vue'),
    meta: {
      breadcrumb: '帳號設定',
      requiresAuth: true
    }
  },
  {
    path: '/member_center/favorites',
    name: 'MemberCenterFavorites',
    component: () => import(/* webpackChunkName: "member_center_favorites" */ '../views/MemberCenterFavorites.vue'),
    meta: {
      breadcrumb: '我的收藏',
      requiresAuth: true
    }
  },
  {
    path: '/member_center/coupons',
    name: 'MemberCenterCoupons',
    component: () => import(/* webpackChunkName: "member_center_coupons" */ '../views/MemberCenterCoupons.vue'),
    meta: {
      breadcrumb: 'E-coupon',
      requiresAuth: true
    }
  },
  {
    path: '/member_center/credits',
    name: 'MemberCenterCredits',
    component: () => import(/* webpackChunkName: "member_center_credits" */ '../views/MemberCenterCredits.vue'),
    meta: {
      breadcrumb: '購物金',
      requiresAuth: true
    }
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset_password" */ '../views/ResetPassword.vue'),
    meta: {
      breadcrumb: '重設密碼',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to) => {
  if(to.hash) { return }

  store.commit('setLoading', true);
  store.commit('closeAllModals');
  window.scrollTo(0, 0);
  document.body.scrollTo(0, 0)

  if (to.matched.length === 0 || (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isLoggedIn)) {
    router.push({name: 'Home'});
  }
})

router.afterEach((to) => {
  if(to.hash) { return }

  document.title = '金革音樂｜豐足心靈・音樂長伴'
  store.commit('setLoading', false);

  if (window.gtag) {
    // GA tracking
    // SPA 所以每次都要記一下 path
    window.gtag('set', 'page_path', to.path);
    window.gtag('event', 'page_view');
  }
})

export default router
