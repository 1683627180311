<template>
  <div
    class="checkbox"
  >
    <img
      v-if="checked"
      src="../assets/checkbox-check.svg"
      alt="checked"
    >
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
    }
  },
  emits: ['change'],
  watch: {
    checked(newValue, oldValue) {
      if(newValue != oldValue) {
        this.$emit('change', newValue);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/variables';

.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid $dark-orange;
  @include flex-center();
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>