<template>
  <section>
    <header>
      <div class="header-background" />
      <h3>精選文章</h3>
    </header>
    <div id="featured-articles">
      <router-link
        v-for="article in articles"
        :key="article.id"
        class="featured-article"
        :to="{name: 'Article', params: {slug: article.slug}}"
      >
        <img
          :src="imgUrl(article.image_preview_url)"
          class="article-image"
        >
        <div class="title">
          {{ article.title }}
        </div>
        <div class="short-description">
          {{ truncateString(article.short_description, 50) }}
        </div>
        <div class="created-on">
          {{ article.created_on }}
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import { inject, onMounted, onUnmounted } from 'vue'
import NumericalMixin from '../mixins/NumericalMixin'

export default {
  mixins: [NumericalMixin],
  props: {
    articles: {
      type: Array,
      required: true
    }
  },
  setup() {
    const imgUrl = inject('imgUrl')

    const bannerRatio = inject('bannerRatio')
    const resizeImages = () => {
      document.querySelector('#featured-articles')
        .querySelectorAll('.article-image').forEach(element => {
        element.style.height = (element.clientWidth * bannerRatio) + 'px'
      })
    }
    onMounted(() => {
      resizeImages()
      window.addEventListener('resize', resizeImages)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizeImages)
    })

    return {
      imgUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#featured-articles {
  display: flex;
  flex-wrap: wrap;
}
.featured-article {
  color: $dark-gray;
  width: calc(25% - 15px);
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;

  &:nth-child(4n) {
    margin-right: 0;
  }
}
.article-image {
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}

.title {
  font-size: 1.5rem;
  line-height: 180%;
  margin-bottom: 10px;
}

.short-description {
  height: 95px;
  line-height: 180%;
  margin-bottom: 10px;
  word-break: break-all;
}
.created-on {
  color: #808080;
}

@media screen and (max-width: $mobile-max-width) {
  .featured-article {
    width: 100%;
    margin-right: 0;
  }
  .short-description {
    height: auto;
  }
}
</style>