<template>
  <section id="home-promotions">
    <header>
      <div class="header-background" />
      <h3>
        優惠活動
      </h3>
    </header>
    <div
      ref="promotionsContainer"
      class="promotions-container"
    >
      <router-link
        v-for="page in promotionPagesNotHideOnHomePage"
        :key="page.id"
        class="promotion"
        :to="{ name: 'Promotion', params: { slug: page.slug }}"
      >
        <img
          :src="imgUrl(page.cover_image_url)"
          class="promotion-img"
        >
      </router-link>
    </div>
  </section>
</template>
<script>
import { inject, ref, onMounted, onUnmounted, computed } from '@vue/runtime-core'

export default {
  setup() {
    const promotionPages = inject('promotionPages')
    const promotionPagesNotHideOnHomePage = computed(() => promotionPages.value.filter(page => !page.hide_on_homepage))
    const imgUrl = inject('imgUrl')

    const promotionsContainer = ref(null)
    const bannerRatio = inject('bannerRatio')
    const resizeImg = () => {
      promotionsContainer.value.querySelectorAll(".promotion").forEach(link => {
        link.style.height = `${link.clientWidth * bannerRatio}px`
        link.querySelector('img').style.height = `${link.clientWidth * bannerRatio}px`
      });
    }
    onMounted(() => {
      resizeImg()
      window.addEventListener('resize', resizeImg)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizeImg)
    })


    return {
      promotionPagesNotHideOnHomePage,
      imgUrl,
      promotionsContainer,
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../css/variables";

.promotions-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.promotion {
  width: calc(50% - 10px);
  margin-right: 20px;
  margin-bottom: 20px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: $mobile-max-width) {
  section#home-promotions {
    background-image: none;
  }
  .promotion {
    width: 100%;
    margin-right: 0;
  }
}
</style>