export default {
  state() {
    return {
      items: JSON.parse(window.localStorage.getItem('cartItems')) || [],
      tempCarts: JSON.parse(window.localStorage.getItem('tempCarts')) || []
    }
  },
  getters: {
    cartItemProductIds(state) {
      return state.items.map(item => item.product_id)
    },
    tempCartsParams(state) {
      return state.tempCarts.map(cart => {
        return {
          promotion_id: cart.promotion_id,
          products: cart.products.map(product => product.id)
        }
      })
    },
    productIdsInCarts(state) {
      let list = []
      state.items.forEach(item => {
        list.push(item.product_id)
        Object.values(item.promotions).forEach(productsInfo => {
          list.push(Object.keys(productsInfo))
        })
      })
      state.tempCarts.forEach(cart => {
        list.push(cart.products)
      })
      list = list.flat(2).map(id => parseInt(id))
      list = list.filter((id, index) => index === list.indexOf(id))
      return list
    },
    cartQuantity(state) {
      let qty = 0
      state.items.forEach(item => {
        qty += item.quantity
        Object.values(item.promotions).forEach(productsInfo => {
          qty += Object.values(productsInfo).reduce((sum, q) => sum + q, 0)
        })
      })
      state.tempCarts.forEach(cart => {
        qty += cart.products.length
      })
      return qty
    }
  },
  mutations: {
    addCartItem(state, item) {
      const productIds = state.items.map(item => item.product_id)
      if(productIds.indexOf(item.product_id) !== -1) {
        const index = productIds.indexOf(item.product_id)
        state.items.splice(index, 1, item)
      } else {
        state.items.push(item)
      }
      window.localStorage.setItem('cartItems', JSON.stringify(state.items))
      // Meta Pixel tracking
      fbq('track', 'ADD_TO_CART');
    },
    updateCartItem(state, payload) {
      let cartItem = state.items.find(cartItem => cartItem.product_id === payload['productId'])
      Object.assign(cartItem, payload['attributes'])
      window.localStorage.setItem('cartItems', JSON.stringify(state.items))
    },
    removeCartItem(state, index) {
      state.items.splice(index, 1)
      window.localStorage.setItem('cartItems', JSON.stringify(state.items))
    },
    updateCartSubItems(state, payload) {
      const item = state.items.find(item => item.product_id === payload.productId)
      Object.assign(item, {promotions: payload.subItems})
      window.localStorage.setItem('cartItems', JSON.stringify(state.items))
    },
    addTempCart(state, cart) {
      state.tempCarts.push(cart)
      window.localStorage.setItem('tempCarts', JSON.stringify(state.tempCarts))
    },
    removeTempCart(state, index) {
      state.tempCarts.splice(index, 1)
      window.localStorage.setItem('tempCarts', JSON.stringify(state.tempCarts))
    },
    emptyCarts(state) {
      state.items = []
      state.tempCarts = []
      window.localStorage.setItem('cartItems', JSON.stringify(state.items))
      window.localStorage.setItem('tempCarts', JSON.stringify(state.tempCarts))
    }
  },
  actions: {

  },
}