import axios from 'axios';
import store from '../store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use(function (config) {
  if (store.getters.isLoggedIn) {
    config.headers.Authorization = 'Bearer ' + store.getters.token
  }

  return config;
});

export default axios
