<template>
  <div
    id="register-step-1"
    class="register-form"
  >
    <div
      class="input-group"
      :class="{invalid: v$.email.$invalid}"
    >
      <label for="email">請輸入有效 E-mail</label>
      <input
        id="email"
        v-model="email"
        type="email"
        @change="inputsChange"
      >
    </div>
    <div
      class="input-group"
      :class="{invalid: v$.password.$invalid}"
    >
      <label for="password">請輸入密碼</label>
      <div class="password-input">
        <input
          id="password"
          v-model="password"
          :type="passwordInputType"
          placeholder="最少六位數字＋英文，需要區別大小寫"
          @change="inputsChange"
        >
        <div
          class="show-password-btn"
          @click="showPassword = !showPassword"
        >
          {{ showPassword ? '隱藏' : '顯示' }}
        </div>
      </div>
    </div>
    <div
      class="input-group"
      :class="{invalid: v$.passwordConfirm.$invalid}"
    >
      <label for="password-confirm">再次確認密碼</label>
      <div class="password-input">
        <input
          id="password-confirm"
          v-model="passwordConfirm"
          :type="passwordInputType"
          placeholder="最少六位數字＋英文，需要區別大小寫"
          @change="inputsChange"
        >
        <div
          class="show-password-btn"
          @click="showPassword = !showPassword"
        >
          {{ showPassword ? '隱藏' : '顯示' }}
        </div>
      </div>
    </div>
    <div class="actions">
      <BaseCircleButton
        text="下一步"
        @click.prevent="nextStep"
      />
    </div>
    <div class="social-login">
      <h5>快速註冊</h5>
      <button @click="fbLogin">
        Facebook 快速註冊
      </button>
    </div>
  </div>
</template>

<script>
import BaseCircleButton from './BaseCircleButton.vue';
import useVuelidate from '@vuelidate/core'
import { required, alphaNum, minLength, email, sameAs } from '@vuelidate/validators'
import { ref, computed, inject } from 'vue'

export default {
  components: {BaseCircleButton},
  props: {
    propsEmail: {
      type: String,
      required: true,
    },
    propsPassword: {
      type: String,
      required: true,
    },
    propsPasswordConfirm: {
      type: String,
      required: true,
    },
  },
  emits: ['data-change', 'next-step'],
  setup (props, {emit}) {
    let emailRef = ref(props.propsEmail)
    let password = ref(props.propsPassword)
    let passwordConfirm = ref(props.propsPasswordConfirm)
    const inputsChange = () => {
      emit('data-change',
        emailRef.value,
        password.value,
        passwordConfirm.value,
      )
    }

    const rules = computed(() => ({
      email: {
        required,
        email,
      },
      password: {
        required,
        alphaNum,
        minLength: minLength(6),
      },
      passwordConfirm: {
        required,
        sameAs: sameAs(password),
      }
    }))
    const v$ = useVuelidate(rules, { email: emailRef, password, passwordConfirm })
    const nextStep = () => {
      v$.value.$touch()
      if (v$.value.$invalid) { return }
      emit('next-step')
    }

    let showPassword = ref(false)
    let passwordInputType = computed(() => showPassword.value ? 'text' : 'password')

    const fbLogin = inject('fbLogin')

    return {
      email: emailRef,
      password,
      passwordConfirm,
      inputsChange,
      showPassword,
      passwordInputType,
      nextStep,
      v$,
      fbLogin
    }
  },
}
</script>

<style lang="scss" scoped>
.actions {
  text-align: right;
}
</style>