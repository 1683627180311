<template>
  <router-link
    :to="{name: 'Product', params: { id: product.id}}"
    class="products-tile"
  >
    <div
      ref="productImg"
      class="image"
    >
      <img
        :src="imgUrl(product.cover_preview)"
        :alt="product.name"
      >
      <div
        class="favorite"
        :class="{active: isFavorited}"
        @click.stop.prevent="toggleFavorite"
      >
        <InlineSvg :src="require('../assets/icon-heart.svg')" />
      </div>
      <div
        v-if="product.status === '未上架'"
        class="preview-badge"
      >
        預覽
      </div>
      <div
        v-else-if="product.quantity === 0"
        class="sold-out"
      >
        售完
      </div>
    </div>
    <p class="product-name">
      {{ truncateString(product.name, isMobile ? 15 : 20) }}
    </p>
    <footer>
      <div class="prices">
        <span class="product-price">{{ numberToCurrency(product.price) }}</span>
        <span
          v-if="product.price !== product.sticker_price"
          class="sticker-price"
        >
          {{ numberToCurrency(product.sticker_price) }}
        </span>
      </div>
      <div
        class="put-in-cart"
        @click.stop.prevent="putInCart"
      >
        <img
          src="../assets/icon-outline-cart.svg"
          alt="放入購物車"
        >
      </div>
    </footer>
  </router-link>
</template>

<script>
import NumericalMixin from "../mixins/NumericalMixin"
import InlineSvg from "vue-inline-svg"
import { inject, onMounted, computed, ref, nextTick, onUnmounted } from 'vue'
import { useStore } from 'vuex'

export default {
  components: { InlineSvg },
  mixins: [NumericalMixin],
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const isMobile = inject('isMobile')
    const imgUrl = inject('imgUrl')

    const store = useStore()
    const addingFavorite = ref(false)
    const isFavorited = computed(() => addingFavorite.value || store.getters.isFavorited(props.product.id))
    const toggleFavorite = async () => {
      addingFavorite.value = true
      await store.dispatch('toggleFavorite', props.product.id)
      addingFavorite.value = false
    }

    const productImg = ref(null)
    const resizeImg = () => {
      productImg.value.style.height = `${productImg.value.clientWidth}px`
    }
    onMounted(() => {
      nextTick(resizeImg)
      window.addEventListener('resize', resizeImg)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizeImg)
    })

    const cartItem = computed(() => {
      return {
        product_id: props.product.id,
        quantity: 1,
        promotion_id: props.product.best_offer_promotion ? props.product.best_offer_promotion.id : null,
        promotions: {}
      }
    })
    const showSwal = inject('showSwal')
    const putInCart = async () => {
      if(!store.getters.isLoggedIn) {
        store.commit('openLogin')
        return
      }

      if(!store.getters.cartItemProductIds.includes(props.product.id) && props.product.quantity > 0) {
        store.commit('addCartItem', cartItem.value)
      } else if (props.product.quantity <= 0) {
        showSwal({
          title: '已無庫存',
          icon: 'warning'
        })
      }
      store.state.showCart = true
    }

    return {
      isMobile,
      imgUrl,
      isFavorited,
      toggleFavorite,
      productImg,
      putInCart
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.products-tile {
  position: relative;
  display: block;
  width: calc((100% - 120px) / 4 );
  margin-bottom: 60px;
  margin-right: 40px;

  &:nth-child(4n) {
    margin-right: 0;
  }
}

.image {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  margin-bottom: 10px;

  .preview-badge {
    @include sold-out-tag();
    color: $dark-green;
    background: $light-green;
  }

  .sold-out {
    @include sold-out-tag();
  }
}

.product-name {
  color: $dark-gray;
  line-height: 180%;
  height: 58px;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prices {
  display: flex;
  align-items: center;
}
.product-price {
  color: $dark-orange;
  font-weight: bold;
  margin-right: 5px;
}
.sticker-price {
  color: $light-gray;
  text-decoration: line-through;
  font-size: 0.75rem;
}
.put-in-cart {
  img {
    width: 25px;
  }
}

.favorite {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 10;

  :deep(&:hover), :deep(&.active) {
    svg path {
      fill-rule: unset;
    }
  }
}

@media screen and (max-width: $mobile-max-width) {
  .products-tile {
    width: calc(50% - 5px) !important;
    margin-right: 10px !important;
    &:nth-child(2n) {
      margin-right: 0 !important;
    }
  }
}
</style>

<style lang="scss">
.favorite:hover svg path, .favorite.active svg path {
  fill-rule: unset;
}
</style>