import axios from 'axios';

export default {
  state() {
    return {
      initialized: false,
      favorites: [],
    }
  },
  getters: {
    favoriteProductIds: state => state.favorites.map(favorite => favorite.product_id),
    isFavorited: (_, getters) => (productId) => {
      return getters.favoriteProductIds.find(id => id === productId)
    },
  },
  mutations: {
    setFavorites(state, favorites) {
      state.favorites = favorites
      state.initialized = true
    },
  },
  actions: {
    getFavorites({ commit }) {
      return new Promise(resolve => {
        axios.get("/api/v1/favorite_products").then(({data}) => {
          commit('setFavorites', data.favorite_products)
          resolve()
        })
      })
    },
    toggleFavorite({ state, commit, getters }, productId) {
      return new Promise((resolve) => {
        if(!getters.isLoggedIn) {
          commit('openLogin')
          resolve()
        }

        if(getters.isFavorited(productId)) {
          const favorite = state.favorites.find(favorite => favorite.product_id === productId)
          axios.delete(`/api/v1/favorite_products/${favorite.id}`).then(({data}) => {
            commit('setFavorites', data.favorite_products)
            resolve()
          })
        } else {
          axios.post("/api/v1/favorite_products", {
            product_id: productId
          }).then(({data}) => {
            commit('setFavorites', data.favorite_products)

            // Meta Pixel tracking
            fbq('track', 'ADD_TO_WISHLIST');

            resolve()
          })
        }
      })
    }
  },
}