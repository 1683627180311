<template>
  <div class="donuts-container">
    <HomePageDonutItem
      v-for="donut in donuts"
      :key="donut.title"
      :donut="donut"
      @click="toProducts(donut.tag)"
    />
  </div>
</template>
<script>
import HomePageDonutItem from './HomePageDonutItem.vue'
import { computed, inject } from 'vue'

export default {
  components: {
    HomePageDonutItem,
  },
  setup() {
    const tags = inject('pagesTags')
    const toProducts = inject('toProductsByTag')
    const colors = [
      'dark-green',
      'light-green',
      'dark-orange',
      'light-orange',
      'blue',
      'gray',
    ]
    const donuts = computed(() => {
      let list = []
      for(let i = 0; i < 6; i++) {
        if(tags.value[i]) {
          list.push({
            tag: tags.value[i],
            title: tags.value[i].name,
            colorName: colors[i],
          })
        }
      }
      return list
    })

    return {
      tags,
      donuts,
      toProducts
    }
  },
}
</script>
<style lang="scss" scoped>
.donuts-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 120px;


  @media screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 10px;
  }
}
</style>