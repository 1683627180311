<template>
  <div class="playlist-song">
    <div class="info">
      <div class="index">
        {{ (index + 1).toString().padStart(2, '0') }}
      </div>
      <div
        class="name"
        :class="{active: isCurrentSong}"
      >
        {{ truncateString(song.title, isMobile ? 10 : 40) }}
      </div>
      <div
        class="controls"
        :class="{active: isCurrentSong && playing}"
      >
        <div
          v-if="playing"
          class="btn pause"
          @click="$emit('pause')"
        >
          <InlineSvg :src="require('../assets/player/pause.svg')" />
        </div>
        <div
          v-else
          class="btn play"
          @click="$emit('play')"
        >
          <InlineSvg :src="require('../assets/player/play.svg')" />
        </div>
      </div>
      <router-link
        :to="song.link"
        class="link"
      >
        {{ isMobile ? '介紹' : '前往介紹' }}
      </router-link>
    </div>
    <div
      v-show="isCurrentSong"
      ref="progressBar"
      class="progress-bar"
      @click="seek"
    >
      <div class="bar-container">
        <div class="bar" />
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, toRef, watch } from 'vue'
import InlineSvg from "vue-inline-svg"
import NumericalMixin from "../mixins/NumericalMixin"

export default {
  components: {
    InlineSvg
  },
  mixins: [NumericalMixin],
  props: {
    song: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    playing: {
      type: Boolean,
    },
    isCurrentSong: {
      type: Boolean,
    },
    cursorPercentage: {
      type: Number,
      required: true
    },
  },
  emits: ['play', 'pause', 'seek'],
  setup(props, { emit }) {
    const progressBar = ref(null)
    const cursorPercentage = toRef(props, 'cursorPercentage')

    watch(cursorPercentage, (percentage) => {
      if (props.isCurrentSong && progressBar.value) {
        progressBar.value.querySelector('.bar').style.width = `${percentage * 100}%`
      }
    })

    const seek = (e) => {
      const percentage = e.offsetX / progressBar.value.querySelector('.bar-container').offsetWidth
      emit('seek', percentage)
    }

    const isMobile = inject('isMobile')

    return {
      progressBar,
      seek,
      isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.playlist-song {
  position: relative;
  height: 70px;
  border-bottom: 1px solid $border-color;
}

.info {
  display: flex;
  align-items: center;
  height: 70px;
}
.index {
  margin-right: 10px;
  width: 20px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}
.name {
  flex-grow: 1;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);

  &.active {
    font-weight: bold;
    color: white;
  }
}
.controls {
  width: 80px;
  display: flex;
  justify-content: center;
  .btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  svg {
    width: 24px;
  }

  :deep(path) {
    fill: rgba(255, 255, 255, 0.7);
  }

  &.active, &:hover {
    :deep(path) {
      fill: white;
    }
  }
}
.link {
  width: 164px;
  height: 100%;
  color: white;
  @include flex-center();

  &:hover {
    background: $light-green;
  }
}

.progress-bar {
  position: absolute;
  height: 10px;
  width: calc(100% - 244px);
  bottom: -1px;
  left: 0;
  cursor: pointer;
}

.bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.bar {
  height: 3px;
  background-color: $light-green;
}

@media screen and (max-width: $mobile-max-width) {
  .link {
    width: auto;
    font-size: 14px
  }

  .controls {
    width: 45px;

    svg {
      width: 15px;
    }
  }

  .progress-bar {
    width: 100%;
  }
}
</style>