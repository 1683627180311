export default {
  methods: {
    numberToCurrency(number) {
      return "$" + new Intl.NumberFormat('zh-TW').format(number)
    },
    toCurrencyNumber(number) {
      return new Intl.NumberFormat('zh-TW').format(number)
    },
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    }
  }
}