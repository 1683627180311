<template>
  <div id="login-login">
    <header>
      <h2>Hello!</h2>
      <button
        class="outline-button"
        @click.prevent="$emit('to-register')"
      >
        尚未註冊，立即成為金革會員！
      </button>
    </header>
    <form
      id="login-form"
      @submit.prevent.stop="logIn"
    >
      <label for="email">請輸入電子信箱</label>
      <input
        id="email"
        v-model="email"
        type="email"
      >
      <div id="password-label">
        <label for="password">請輸入密碼</label>
        <span @click="$emit('forgot-password')">忘記密碼？</span>
      </div>
      <div class="password-input">
        <input
          id="password"
          v-model="password"
          :type="passwordInputType"
        >
        <div
          class="show-password-btn"
          @click="showPassword = !showPassword"
        >
          {{ showPassword ? '隱藏' : '顯示' }}
        </div>
      </div>
      <BaseCircleButton
        :disabled="submitting"
        :text="submitting ? '登入中...' : '登入'"
      />
    </form>
    <div class="social-login">
      <h5>社群登入</h5>
      <button @click="fbLogin">
        Facebook 登入
      </button>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';
import BaseCircleButton from './BaseCircleButton.vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
  components: {BaseCircleButton},
  emits: ['to-register', 'close-login', 'forgot-password'],
  setup(_, { emit }) {
    const email = ref('')
    const password = ref('')
    const showPassword = ref(false)
    const passwordInputType = computed(() => showPassword.value ? "text" : "password")

    const store = useStore()
    const showSwal = inject('showSwal')
    const submitting = ref(false)
    const logIn = async () => {
      if (submitting.value) return

      submitting.value = true
      store.commit('setLoading', true);
      try {
        const { data } = await axios.post("/api/e_shop_infos/sign_in", {
          api_e_shop_info: {
            email: email.value,
            password: password.value,
          }
        })
        await store.dispatch('login', data.token)
        showSwal({title: '登入成功'})
        emit('close-login')
      } catch(error) {
        showSwal({
          title: `登入失敗：${error.response.data.error}`,
          icon: 'error'
        })
        submitting.value = false
      }
      store.commit('setLoading', false);
    }

    const fbLogin = inject('fbLogin')

    return {
      email,
      password,
      showPassword,
      passwordInputType,
      logIn,
      fbLogin,
      submitting
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#login-login {
  position: relative;
  z-index: 110;
}

header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

h2 {
  font-size: 2rem;
  margin-right: 20px;
}
#login-form {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 130px;
  width: 609px;

  label {
    line-height: 25px;
    margin-bottom: 6px;
  }

  input {
    border: none;
    border-bottom: 1px solid rgba(33, 33, 33, 0.2);
    background: transparent;
    font-size: 1.2rem;
    line-height: 34px;
    padding-left: 5px;
  }

  input#email {
    margin-bottom: 80px;
  }

  #password-label {
    label {
      margin-right: 20px;
    }

    span {
      color: $light-gray;
      cursor: pointer;
      font-size: 0.875rem;
    }
  }

  input#password {
    margin-bottom: 40px;
  }

  .password-input {
    position: relative;
    input {
      width: 100%;
    }
    .show-password-btn {
      position: absolute;
      right: 20px;
      top: 5px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    align-items: start;
    flex-direction: column;
    h2 {
      line-height: 180%;
      margin-bottom: 10px;
    }
  }

  #login-form {
    width: 100%;
    margin-bottom: 50px;
  }

  .social-login {
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 200px;
    }
  }
}
</style>