<template>
  <div
    id="cart"
    @click.stop
  >
    <BaseCross
      class="close-btn"
      @click="close"
    />
    <h3>購物車 ({{ ordsum.orddets.length }})</h3>
    <div
      v-if="initializing || !ordsum"
      id="cart-orddets"
      class="spinner-container"
    >
      <div class="spinner" />
    </div>
    <div
      v-else-if="isEmpty"
      class="empty"
    >
      <p>目前沒有商品</p>
    </div>
    <div
      v-else
      id="carts"
    >
      <div class="main-cart">
        <CartOrddet
          v-for="orddet in cartItems.mainCart"
          :key="`main-cart-orddet-${orddet.product_id}`"
          :orddet="orddet"
          :calculating="calculating"
          @quantity-changed="qtyChanged(orddet.product_id, $event)"
        />
      </div>
      <div
        v-for="(cart, index) in cartItems.tempCarts"
        :key="index"
        class="temp-cart"
      >
        <div class="promotion-title">
          <span class="badge">組合</span>
          <span>{{ cart.promotion.name }}</span>
        </div>
        <CartSubOrddet
          v-for="orddet in cart.orddets"
          :key="orddet.product_id"
          :orddet="orddet"
        />
      </div>
    </div>
    <footer>
      <div class="left">
        <div class="subtotal">
          <span>
            小計：
          </span>
          <div
            v-if="calculating"
            class="spinner"
          />
          <span
            v-else
            class="price"
          >
            {{ numberToCurrency(ordsum.orddets.length > 0 ? ordsum.subtotal : 0) }}
          </span>
        </div>
        <p />
      </div>
      <div
        class="check"
        @click="goCheck"
      >
        前往結帳
      </div>
    </footer>
  </div>
</template>

<script>
import BaseCross from './BaseCross.vue';
import CartOrddet from './CartOrddet.vue';
import CartSubOrddet from "./CartSubOrddet.vue"
import { useStore } from 'vuex'
import { ref, reactive, onMounted, computed } from 'vue';
import NumericalMixin from '../mixins/NumericalMixin';
import cartMixin from "../mixins/cartMixin";
import { useRouter } from 'vue-router';

export default {
  components: { BaseCross, CartOrddet, CartSubOrddet },
  mixins: [NumericalMixin],
  setup() {
    const store = useStore()
    const close = () => {
      store.commit('closeAllModals')
    }

    const isEmpty = computed(() => store.state.cart.items.length + store.state.cart.tempCarts.length === 0)

    const initializing = ref(true)
    const  {
      calculating,
      calculate,
      cartItems,
    } = cartMixin()
    const ordsum = reactive({
      contact_id: store.state.user.id,
      category: '電商',
      deliver_method: '自取',
      orddets: []
    })
    onMounted(async () => {
      if(!isEmpty.value) {
        await calculate(ordsum)
      }
      initializing.value = false
    })

    const qtyChanged = (productId, quantity) => {
      store.commit('updateCartItem', {
        productId,
        attributes: { quantity }
      })
      calculate(ordsum)
    }

    const router = useRouter()
    const goCheck = () => {
      store.commit('closeAllModals')
      router.push({name: 'NewOrdsum'})
    }

    return {
      initializing,
      isEmpty,
      close,
      ordsum,
      cartItems,
      calculating,
      qtyChanged,
      goCheck
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#cart {
  position: fixed;
  right: 0;
  top: 0;
  background-color: $background-color;
  width: 600px;
  height: 100vh;
  z-index: 2000;
}

.close-btn {
  position: absolute;
  z-index: 2010;
  top: 30px;
  right: 30px;
}

h3 {
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  font-size: 20px;
  height: 80px;
  padding: 30px 0 15px 30px;
}
.empty {
  height: calc(100vh - 170px);
  padding: 30px;
  text-align: center;
}
#carts {
  height: calc(100vh - 170px);
  overflow-y: scroll;
}
.main-cart {
  padding: 20px 30px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

footer {
  height: 90px;
  background: $light-orange;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .subtotal{
    font-size: 19px;
    line-height: 24px;
    display: flex;
    .price {
      color: $dark-orange;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .check {
    color: $dark-orange;
    background: transparent;
    border: 1px solid $dark-orange;
    border-radius: 30px;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: $dark-orange;
      color: white;
    }
  }
}

.temp-cart {
  padding: 20px 30px;
  border-bottom: 1px solid $border-color;
  .promotion-title {
    display: flex;
    align-items: center;
    color: $dark-orange;
    margin-bottom: 10px;

    .badge {
      padding: 0 3px;
      border: 1px solid $dark-orange;
      margin-right: 10px;
    }
  }
}

.spinner-container {
  @include flex-center();
}
.spinner {
  width:25px;
  height:25px;
  border-radius:50%;
  background:conic-gradient(#0000 10%,$dark-orange);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
  animation:s3 1s infinite linear;
}
@keyframes s3 {to{transform: rotate(1turn)}}

@media screen and (max-width: $mobile-max-width) {
  #cart {
    width: 100vw;
    height: 100vh;
  }

  #carts {
    padding-top: 20px;
  }

  .main-cart {
    padding: 0 15px;
    position: relative;
  }
  .temp-cart {
    padding: 20px 15px;
    position: relative;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
  }
}
</style>