<template>
  <div
    id="register-step-2"
    class="register-form"
  >
    <div
      class="input-group"
      :class="{invalid: v$.name.$invalid}"
    >
      <label for="name">請輸入真實姓名</label>
      <input
        id="name"
        v-model="name"
        type="text"
        @change="inputsChange"
      >
    </div>
    <div
      class="input-group"
      :class="{invalid: v$.mobile.$invalid}"
    >
      <label for="mobile">請輸入有效手機</label>
      <input
        id="mobile"
        v-model="mobile"
        type="tel"
        @change="inputsChange"
      >
    </div>
    <div
      class="input-group"
      :class="{invalid: v$.birthday.$invalid}"
    >
      <label for="birthday">生日</label>
      <input
        id="birthday"
        v-model="birthday"
        type="date"
        @change="inputsChange"
      >
    </div>
    <div
      class="checkbox-group"
      :class="{invalid: !agreeToMemberPolicy}"
      @click="agreeToMemberPolicy = !agreeToMemberPolicy"
    >
      <BaseCheckbox :checked="agreeToMemberPolicy" />
      <label>
        我同意
        <router-link
          :to="{ name: 'TermsAndStatements' }"
          target="_blank"
        >
          會員條款
        </router-link>
      </label>
    </div>
    <div
      class="checkbox-group"
      @click="agreeToEpaper = !agreeToEpaper"
    >
      <BaseCheckbox
        :checked="agreeToEpaper"
        @change="inputsChange"
      />
      <label>我想收到電子優惠訊息</label>
    </div>
    <div
      class="checkbox-group"
      @click="agreeToBeCalled = !agreeToBeCalled"
    >
      <BaseCheckbox
        :checked="agreeToBeCalled"
        @change="inputsChange"
      />
      <label>我願意接受專屬客服人員服務</label>
    </div>
    <div
      class="checkbox-group"
      @click="agreeToMusicPaper = !agreeToMusicPaper"
    >
      <BaseCheckbox
        :checked="agreeToMusicPaper"
        @change="inputsChange"
      />
      <label>我願意接受實體金革音樂季報（可至會員中心新增收件地址）</label>
    </div>
    <div class="actions">
      <BaseCircleButton
        text="上一步"
        @click.prevent="prevStep"
      />
      <div class="next-step">
        <p>
          還有 1 個步驟就完成囉！
        </p>
        <BaseCircleButton
          text="下一步"
          @click.prevent="nextStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseCircleButton from './BaseCircleButton.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { ref } from 'vue';

export default {
  components: { BaseCircleButton, BaseCheckbox },
  props: {
    propsName: {
      type: String,
      required: true,
    },
    propsMobile: {
      type: String,
      required: true,
    },
    propsBirthday: {
      type: String,
      required: true,
    },
    propsAgreeToEpaper: {
      type: Boolean
    },
    propsAgreeToBeCalled: {
      type: Boolean
    },
    propsAgreeToMusicPaper: {
      type: Boolean
    },
  },
  emits: ['data-change', 'next-step', 'prev-step'],
  setup(props, {emit}) {
    let name = ref(props.propsName)
    let mobile = ref(props.propsMobile)
    let birthday = ref(props.propsBirthday)
    let agreeToMemberPolicy = ref(false)
    let agreeToEpaper = ref(props.propsAgreeToEpaper)
    let agreeToBeCalled = ref(props.propsAgreeToBeCalled)
    let agreeToMusicPaper = ref(props.propsAgreeToMusicPaper)
    const inputsChange = () => {
      emit('data-change', name.value, mobile.value, birthday.value, agreeToEpaper.value, agreeToBeCalled.value, agreeToMusicPaper.value)
    }

    const rules = {
      name: { required, minLength: minLength(2) },
      mobile: { required, minLength: minLength(10) },
      birthday: { required },
    }
    const v$ = useVuelidate(rules, { name, mobile, birthday })

    const nextStep = () => {
      v$.value.$touch()
      if (v$.value.$invalid || !agreeToMemberPolicy.value) { return }
      emit('next-step')
    }
    const prevStep = () => { emit('prev-step') }

    return {
      name,
      mobile,
      birthday,
      agreeToMemberPolicy,
      agreeToEpaper,
      agreeToBeCalled,
      agreeToMusicPaper,
      inputsChange,
      v$,
      nextStep,
      prevStep,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.input-group:nth-child(3) {
  margin-bottom: 20px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  label {
    width: calc(100% - 24px);
    margin-left: 12px;
    color: $light-gray;
    font-size: 0.875rem;
    @include transition(color 0.2s ease);

    a {
      color: $dark-orange;
    }
  }

  &.invalid {
    label {
      color: $dark-orange;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.next-step {
  display: flex;
  align-items: center;
  p {
    font-size: 1rem;
    color: $dark-green;
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .next-step {
    flex-direction: column-reverse;
    align-items: flex-end;
    p {
      margin-right: 0;
    }
  }
}
</style>