<template>
  <div
    id="register-step-3"
    class="register-form"
  >
    <p>
      只差一步就成功囉！最後一步，請選擇音樂喜好：
    </p>
    <div class="likes">
      <h3>曲風</h3>
      <div class="like-items">
        <div
          v-for="genre in genres"
          :key="genre"
          class="like-item"
          :class="{selected: itemIncluded(genre)}"
          @click="toggleGenre(genre)"
        >
          <img
            :src="svgPath(genre)"
            :alt="genre"
          >
          <span>{{ genre }}</span>
        </div>
      </div>
    </div>
    <div class="likes">
      <h3>格式</h3>
      <div class="like-items">
        <div
          v-for="format in formats"
          :key="format"
          class="like-item"
          :class="{selected: itemIncluded(format)}"
          @click="toggleFormat(format)"
        >
          <img
            :src="svgPath(format)"
            :alt="format"
          >
          <span>{{ format }}</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <BaseCircleButton
        text="上一步"
        @click.prevent="prevStep"
      />
      <BaseCircleButton
        :disabled="submitting"
        :text="submitting ? '送出中...' : '完成'"
        @click.prevent="finish"
      />
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import BaseCircleButton from './BaseCircleButton.vue'
const jazzSvg = require("../assets/login/icon-jazz.svg");
const classicSvg = require("../assets/login/icon-piano.svg");
const babySvg = require("../assets/login/icon-baby.svg");
const meditateSvg = require("../assets/login/icon-meditation.svg");
const popSvg = require("../assets/login/icon-pop.svg");
const format1Svg = require("../assets/login/icon-signup-1.svg");
const format2Svg = require("../assets/login/icon-signup-2.svg");
const format3Svg = require("../assets/login/icon-signup-3.svg");
const format4Svg = require("../assets/login/icon-signup-4.svg");
const format5Svg = require("../assets/login/icon-signup-5.svg");

export default {
  components: {BaseCircleButton},
  props: {
    genreList: {
      type: Array,
      required: true
    },
    formatList: {
      type: Array,
      required: true
    },
    submitting: {
      type: Boolean,
    },
  },
  emits: ['data-change', 'prev-step', 'finish'],
  setup(props, {emit}) {
    const genresSelected = ref(props.genreList)
    const formatsSelected = ref(props.formatList)
    const genres = ['爵士', '古典', '親子', '心靈', '流行']
    const formats = ['CD', '黑膠', 'USB', 'DVD/BD', '數位音樂']

    const svgPath = (name) => {
      switch(name) {
        case '爵士':
          return jazzSvg;
        case '古典':
          return classicSvg;
        case '親子':
          return babySvg;
        case '心靈':
          return meditateSvg;
        case '流行':
          return popSvg;
        case 'CD':
          return format1Svg;
        case '黑膠':
          return format2Svg;
        case 'USB':
          return format3Svg;
        case 'DVD/BD':
          return format4Svg;
        case '數位音樂':
          return format5Svg;
        default:
          return '';
      }
    }

    const itemIncluded = (name) => {
      return genresSelected.value.includes(name) || formatsSelected.value.includes(name)
    }
    const toggleGenre = (name) => {
      if (itemIncluded(name)) {
        genresSelected.value = genresSelected.value.filter(genre => genre !== name)
      } else {
        genresSelected.value.push(name)
      }
      emit('data-change', genresSelected.value, formatsSelected.value)
    }
    const toggleFormat = (name) => {
      if (itemIncluded(name)) {
        formatsSelected.value = formatsSelected.value.filter(format => format !== name)
      } else {
        formatsSelected.value.push(name)
      }
      emit('data-change', genresSelected.value, formatsSelected.value)
    }

    const prevStep = () => {
      emit('prev-step')
    }

    const showSwal = inject('showSwal')
    const finish = () => {
      if(genresSelected.value.length > 0 && formatsSelected.value.length > 0) {
        emit('finish');
      } else {
        showSwal({ title: '請選擇音樂喜好及格式'})
      }
    }
    return {
      genresSelected,
      formatsSelected,
      genres,
      formats,
      svgPath,
      itemIncluded,
      toggleGenre,
      toggleFormat,
      prevStep,
      finish
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

p {
  font-size: 0.875rem;
  margin-bottom: 15px;
}

.likes {
  margin-bottom: 40px;
  h3 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .like-items {
    display: flex;
    flex-wrap: wrap;
  }
  .like-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 28px;
    width: 72px;
    height: 95px;
    padding-top: 8px;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 15px;
    }

    &.selected {
      background-color: rgba(215, 82, 53, 0.3);
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
</style>