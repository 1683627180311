<template>
  <div class="cart-orddet">
    <div class="main-orddet">
      <div
        class="cover"
        @click="gotoProductPage"
      >
        <img :src="imgUrl(product.cover_thumb)">
      </div>
      <div class="main-orddet-info">
        <div class="upper">
          <div
            class="name"
            @click="gotoProductPage"
          >
            {{ product.name }}
          </div>
          <div
            v-if="!isMobile"
            class="price"
          >
            <div
              v-if="calculating"
              class="spinner"
            />
            <template v-else>
              {{ numberToCurrency(price) }}
            </template>
          </div>
        </div>
        <div class="lower">
          <div
            v-if="isMobile"
            class="price"
          >
            <div
              v-if="calculating"
              class="spinner"
            />
            <template v-else>
              {{ numberToCurrency(price) }}
            </template>
          </div>
          <BaseQuantityAdjuster
            :quantity="quantity"
            @add-one="changeQuantity(1)"
            @remove-one="changeQuantity(-1)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="orddet.gifts.length > 0"
      class="sub-orddets"
    >
      <p>已選贈品</p>
      <CartSubOrddet
        v-for="subOrddet in orddet.gifts"
        :key="`sub-orddet-${subOrddet.product_id}`"
        :orddet="subOrddet"
      />
    </div>
    <div
      v-if="orddet.addons.length > 0"
      class="sub-orddets"
    >
      <p>已選加購品</p>
      <CartSubOrddet
        v-for="subOrddet in orddet.addons"
        :key="`sub-orddet-${subOrddet.product_id}`"
        :orddet="subOrddet"
      />
    </div>
  </div>
</template>

<script>
import BaseQuantityAdjuster from "./BaseQuantityAdjuster.vue";
import { inject, computed } from 'vue'
import NumericalMixin from '../mixins/NumericalMixin'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import CartSubOrddet from "./CartSubOrddet.vue";

export default {
  components: { BaseQuantityAdjuster, CartSubOrddet },
  mixins: [NumericalMixin],
  props: {
    orddet: {
      type: Object,
      required: true
    },
    calculating: {
      type: Boolean
    },
  },
  emits: ['quantity-changed'],
  setup(props, { emit }) {
    const product = computed(() => props.orddet.product || {})
    const quantity = computed(() => props.orddet.quantity)
    const price = computed(() => props.orddet.price)

    const imgUrl = inject('imgUrl')

    const router = useRouter()
    const store = useStore()
    const gotoProductPage = () => {
      router.push({
        name: 'Product',
        params: {
          id: product.value.id
        }
      })
      store.commit('closeAllModals')
    }

    const showSwal = inject('showSwal')
    const changeQuantity = async (delta) => {
      if(quantity.value + delta > 0 && quantity.value + delta <= product.value.quantity) {
        emit('quantity-changed', quantity.value + delta)
      } else {
        showSwal({
          title: quantity.value + delta <= 0 ? '請至結帳頁取消' : '已無足夠庫存',
          icon: 'error',
        })
      }
    }

    const isMobile = inject('isMobile')

    return {
      product,
      quantity,
      price,
      imgUrl,
      gotoProductPage,
      changeQuantity,
      isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.cart-orddet {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:nth-last-child(1) {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.cover {
  width: 90px;
  height: 90px;
  margin-right: 10px;
  cursor: pointer;

  img {
    height: 90px;
    width: 90px;
    object-fit: cover;
  }
}

.main-orddet {
  display: flex;
  width: 100%;
}
.main-orddet-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90px;
  width: calc(100% - 100px);
  .upper {
    display: flex;
    justify-content: space-between;
    .name {
      width: 330px;
      cursor: pointer;
    }
  }
  .lower {
    display: flex;
    justify-content: space-between;
  }
}
.price {
  color: $dark-orange;
  font-weight: 700;
}

.sub-orddets {
  margin-top: 10px;
  border-top: 1px solid $border-color;
  padding-top: 10px;
  width: calc(100% - 100px);
  position: relative;
  & > p {
    font-size: 0.875rem;
    color: $light-gray;
    margin-bottom: 5px;
  }
}

.spinner {
  width: 14px;
  height: 14px;
  border-radius:50%;
  background:conic-gradient(#0000 10%,$dark-orange);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 2px),#000 0);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 2px),#000 0);
  animation:s3 1s infinite linear;
}
@keyframes s3 {to{transform: rotate(1turn)}}

@media screen and (max-width: $mobile-max-width) {
  .sub-orddets {
    width: 100%;
  }
}
</style>