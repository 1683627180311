import { createStore } from 'vuex'
import jwtDecode from "jwt-decode"
import axios from "axios"
import cart from "./cart"
import favorites from "./favorites"

export default createStore({
  state: {
    token: localStorage.getItem('token') || '',
    user: null,
    showLogin: false,
    showCart: false,
    showProductTags: false,
    showPromotions: false,
    loading: false,
    isMobileMenuOpen: false,
    showMobileFilters: false,
  },
  getters: {
    token: state => state.token,
    isLoggedIn(_, getters) {
      if(getters.token === '') {
        return false
      }
      try {
        const payload = jwtDecode(getters.token)
        // expired or not
        return payload.exp > Date.now() / 1000
      } catch {
        return false
      }
    },
    currentUser(state) {
      return state.user
    },
    anyModalShowed(state) {
      return state.showLogin ||
        state.showCart ||
        state.showProductTags ||
        state.isMobileMenuOpen ||
        state.showMobileFilters ||
        state.showPromotions;
    },
  },
  mutations: {
    setToken(state, token) {
      window.localStorage.setItem('token', token)
      state.token = token
    },
    clearToken(state) {
      window.localStorage.removeItem('token')
      state.token = ''
    },
    setUser(state, user) {
      state.user = user
    },
    openLogin(state) {
      state.showLogin = true
    },
    closeLogin(state) {
      state.showLogin = false
    },
    toggleLogin(state) {
      state.showLogin = !state.showLogin
    },
    openCart(state) {
      state.showCart = true
    },
    openModal(state, modalName) {
      state[modalName] = true
    },
    closeAllModals(state) {
      state.showLogin = false
      state.showCart = false
      state.isMobileMenuOpen = false
      state.showProductTags = false
      state.showMobileFilters = false
      state.showPromotions = false
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    toggleMobileMenu(state) {
      state.isMobileMenuOpen = !state.isMobileMenuOpen
    },
    closeMobileMenu(state) {
      state.isMobileMenuOpen = false
    },
  },
  actions: {
    async login({ commit, dispatch }, token) {
      commit('setToken', token)
      await dispatch('getSession')
      return Promise.resolve()
    },
    async logout({ dispatch }) {
      window.FB.logout()
      await dispatch('clearSession')
      location.href='/'
      return Promise.resolve()
    },
    async getUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/contact').then(({data}) => {
          commit('setUser', data.contact)
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    async getSession({ dispatch }) {
      try {
        await dispatch('getUser')
        await dispatch('getFavorites')
      } catch (err) {
        // 抓資料有誤則清 token
        await dispatch('clearSession')
      }
      return Promise.resolve()
    },
    async clearSession({ commit }) {
      commit('clearToken')
      commit('setUser', null)
      commit('setFavorites', [])
      return Promise.resolve()
    }
  },
  modules: {
    cart,
    favorites
  }
})
