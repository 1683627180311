<template>
  <div class="cross-button">
    <div class="cross">
      <span />
      <span />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.cross-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:hover {
    .cross span {
      border-color: rgba(51, 51, 51, 0.8);
    }
  }
}

.cross {
  position: relative;
  cursor: pointer;
  span {
    position: absolute;
    top: 15px;
    width: 30px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  }

  span:nth-child(1) {
    transform: rotate(45deg);
  }
  span:nth-child(2) {
    transform: rotate(-45deg);
  }
}
</style>