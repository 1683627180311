<template>
  <div id="promotions-modal">
    <div class="promotion-links">
      <router-link
        v-for="page in list"
        :key="page.id"
        class="promotion-link"
        :to="{ name: 'Promotion', params: { slug: page.slug } }"
      >
        {{ page.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  setup() {
    const list = inject('promotionPages')

    return {
      list,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#promotions-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 600px;
  height: 100vh;
  z-index: 2000;
  background-color: $background-color;
  padding: 50px 100px;
}

.promotion-links {
  width: 100%;
  position: relative;
  overflow-y: scroll;
}
.promotion-link {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
  color: $dark-gray;

  &:hover {
    background-color: $light-orange;
  }
}
</style>