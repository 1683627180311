<template>
  <section>
    <header>
      <div class="header-background" />
      <h3>推薦商品</h3>
    </header>
    <div class="products">
      <BaseProductTile
        v-for="product in products"
        :key="product.id"
        :product="product"
        class="product-tile"
      />
    </div>
    <div class="get-more-btn">
      <router-link :to="{ name: 'Products' }">
        全商品
      </router-link>
    </div>
  </section>
</template>

<script>
import { onMounted, ref, inject } from 'vue';
import axios from 'axios';
import BaseProductTile from "./BaseProductTile.vue"

export default {
  components: {
    BaseProductTile
  },
  setup() {
    const imgUrl = inject('imgUrl')
    const isMobile = inject('isMobile')

    const products = ref([]);
    onMounted(async () => {
      const {data} = await axios.get("/api/v1/pages/featured_products")
      products.value = data.products
    });

    return {
      imgUrl,
      isMobile,
      products,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.products {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
}
.product-tile {
  width: calc(20% - 28px);
  margin-right: 35px;

  &:nth-child(4n) {
    margin-right: 35px;
  }

  &:nth-child(5n) {
    margin-right: 0;
  }
}

.get-more-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;

  a {
    border-radius: 100px;
    height: 80px;
    width: 80px;
    border: solid 1px $dark-green;
    color: $dark-green;
    @include flex-center();

    &:hover {
      color: white;
      background: url('../assets/round_btn_hover.png') center center;
      background-size: cover;
    }
  }
}

@media screen and (max-width: $mobile-max-width) {
  .product-tile {
    width: calc(50% - 5px);
    margin-right: 10px;

    &:nth-child(5n) {
      margin-right: 10px;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
</style>