<template>
  <div id="base-loading">
    <div class="loading-container">
      <div class="spinner" />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#base-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
}

.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
  @include flex-center();
}

.spinner {
  width: 50px;
  height: 50px;
  display: grid;
  border:4px solid #0000;
  border-radius: 50%;
  border-right-color:#25b09b;
  animation: s5 1s infinite linear;
}
.spinner::before,
.spinner::after {
  content:"";
  grid-area: 1/1;
  margin:2px;
  border:inherit;
  border-radius: 50%;
  animation: s5 2s infinite;
}
.spinner::after {
  margin:8px;
  animation-duration: 3s;
}

@keyframes s5{
  100%{transform: rotate(1turn)}
}
</style>