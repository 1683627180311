<template>
  <div
    class="base-playlist-song"
    :class="{'active': isPlaying}"
    @click='clicked'
  >
    <div class="index">
      {{ (index + 1).toString().padStart(2, '0') }}
    </div>
    <div class="name">
      {{ song.title }}
    </div>
    <div
      class="controls"
      :class="{active: isPlaying}"
    >
      <div
        v-if="isPlaying"
        class="btn pause"
      >
        <InlineSvg :src="require('../assets/player/pause.svg')" />
      </div>
      <div
        v-else
        class="btn play"
      >
        <InlineSvg :src="require('../assets/player/play.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { computed, inject } from 'vue'

export default {
  components: { InlineSvg },
  props: {
    song: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['play', 'pause'],
  setup(props, { emit }) {
    const playing = inject('playing')
    const currentSong = inject('currentSong')
    const isPlaying = computed(() => playing.value && currentSong.value && props.song.id === currentSong.value.id)
    const clicked = () => {
      if (isPlaying.value) {
        emit('pause')
      } else {
        emit('play')
      }
    }

    return {
      isPlaying,
      clicked
    }
  }
}
</script>

<style lang="scss" scoped>
.base-playlist-song {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding-right: 25px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  cursor: pointer;

  .index {
    color: #808080;
    margin-right: 10px;
  }

  .name {
    flex-grow: 1;
    color: #808080;
  }

  .btn {
    margin: 0;
  }

  :deep(path), :deep(rect) {
    fill: #808080;
  }

  &.active, &:hover {
    .index, .name {
      color: #333;
    }

    :deep(path), :deep(rect) {
    fill: #333;
  }
  }
}
</style>