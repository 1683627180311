<template>
  <BaseLoading v-if="initializing" />
  <div
    v-else
    id="jingo-app"
  >
    <transition name="fade">
      <BaseLoading v-if="loading" />
    </transition>

    <Header
      v-if="!isMobile"
      @open-menu="headerMenuOpened = !headerMenuOpened"
    />
    <HeaderMobile v-else />
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        v-if="headerMenuOpened"
        id="sub-menu"
      >
        <router-link :to="{name: 'MemberCenter'}">
          會員中心
        </router-link>
        <router-link :to="{name: 'MemberCenterFavorites'}">
          我的收藏
        </router-link>
        <a @click.prevent="logOut">登出</a>
      </div>
    </transition>

    <!-- Modals START -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        v-if="anyModalShowed"
        id="modal-background"
        @click="closeAllModals"
      />
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <MobileMenu v-if="isMobileMenuOpen" />
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInRight"
      leave-active-class="animate__animated animate__slideOutRight"
    >
      <!-- 登入畫面 -->
      <Login v-if="showLogin" />
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInRight"
      leave-active-class="animate__animated animate__slideOutRight"
    >
      <Cart v-if="showCart" />
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInLeft"
      leave-active-class="animate__animated animate__slideOutLeft"
    >
      <AppProductTagsModal v-if="showProductTags" />
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInLeft"
      leave-active-class="animate__animated animate__slideOutLeft"
    >
      <AppPromotionsModal v-if="showPromotions" />
    </transition>
    <!-- Modals END -->

    <div
      class="container"
      :style="$router.currentRoute.value.name === 'About' ? 'max-width: none' : ''"
    >
      <router-view />
    </div>
    <Footer />
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInUp"
      leave-active-class="animate__animated animate__slideOutDown"
    >
      <AppAudioPlayer
        v-if="playlist.id"
        :audio="audio"
        :playlist="playlist"
      />
    </transition>
  </div>
  <audio
    ref="audio"
    :src="audioSrc"
  />
</template>

<script>
import Header from "./components/Header.vue"
import HeaderMobile from "./components/HeaderMobile.vue"
import Footer from "./components/Footer.vue"
import Login from "./components/Login.vue"
import Cart from "./components/Cart.vue"
import BaseLoading from "./components/BaseLoading.vue"
import MobileMenu from "./components/MobileMenu.vue"
import AppProductTagsModal from "./components/AppProductTagsModal.vue"
import AppPromotionsModal from "./components/AppPromotionsModal.vue"
import AppAudioPlayer from "./components/AppAudioPlayer.vue"
import { ref, computed, onMounted, provide } from "vue"
import { useStore } from 'vuex'
import axios from 'axios'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

export default {
  components: {
    Header,
    HeaderMobile,
    Footer,
    Login,
    BaseLoading,
    MobileMenu,
    Cart,
    AppProductTagsModal,
    AppPromotionsModal,
    AppAudioPlayer
  },
  setup() {
    const initializing = ref(true)
    const store = useStore()
    const loading = computed(() => store.state.loading)

    let isMobile = ref(window.innerWidth <= 768)
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768 && isMobile.value) {
        isMobile.value = false
      } else if (window.innerWidth <= 768 && !isMobile.value) {
        isMobile.value = true
      }
    })
    provide('isMobile', isMobile)

    const imgUrl = (path) => {
      if(path && path.startsWith('http')) {
        return path
      } else {
        return `${process.env.VUE_APP_API_URL}${path}`
      }
    }
    provide('imgUrl', imgUrl)
    const bannerRatio = 0.4285  // 3 : 7
    provide('bannerRatio', bannerRatio)

    // 登入相關
    const isLoggedIn = computed(() => store.getters.isLoggedIn)
    const currentUser = computed(() => store.getters.currentUser)
    const isMobileMenuOpen = computed(() => store.state.isMobileMenuOpen)
    const showLogin = computed(() => store.state.showLogin && !currentUser.value)
    const showCart = computed(() => store.state.showCart && currentUser.value)
    const showProductTags = computed(() => store.state.showProductTags)
    const showPromotions = computed(() => store.state.showPromotions)
    const anyModalShowed = computed(() => store.getters.anyModalShowed)
    const closeAllModals = () => store.commit('closeAllModals')

    // 後台設定好的商品 Tags
    const pagesTags = ref([])
    onMounted(async () => {
      const {data} = await axios.get("/api/v1/pages/tags")
      pagesTags.value = data.pages_tags
    })
    const router = useRouter()
    const toProductsByTag = (tag) => {
      if(tag.name) {
        router.push(`/products?products_grid[${tag.context}][]=${encodeURIComponent(tag.name)}`)
      } else {
        router.push({name: 'Products'})
      }
    }
    provide('pagesTags', pagesTags)
    provide('toProductsByTag', toProductsByTag)

    // 優惠活動及優惠活動頁
    const allPromotions = ref([])
    const getPromotions = async() => {
      const {data} = await axios.get("/api/v1/promotions")
      allPromotions.value = data.promotions
    }
    const allPromotionPages = ref([])
    const promotionPages = computed(() => allPromotionPages.value.filter(page => !page.hidden))
    const getPromotionPages = async () => {
      const {data} = await axios.get("/api/v1/pages/promotions")
      allPromotionPages.value = data.pages_promotions
    }
    provide('allPromotionPages', allPromotionPages)
    provide('promotionPages', promotionPages)
    provide('allPromotions', allPromotions)

    onMounted(async () => {
      // get contact, favorites
      if(isLoggedIn.value) {
        await store.dispatch('getSession')
      } else {
        await store.dispatch('clearSession')
      }

      // 抓所有優惠活動
      await getPromotions()
      await getPromotionPages()
      if(isLoggedIn.value) {
        // 刪掉在購物車內已經過期的活動商品
        const allPromotionIds = allPromotions.value.map(x => x.id)
        store.state.cart.items.forEach((cartItem, index) => {
          if(Object.keys(cartItem.promotions).length === 0) {
            return
          } else if(allPromotionIds.indexOf(cartItem.promotion_id) === -1 ||
            !Object.keys(cartItem.promotions).every(id => allPromotionIds.includes(parseInt(id)))
          ) {
            store.commit('removeCartItem', index)
          }
        })
        store.state.cart.tempCarts.forEach((cart, index) => {
          if(allPromotionIds.indexOf(cart.promotion_id) === -1) {
            store.commit('removeTempCart', index)
          }
        })
      }
      initializing.value = false
    })

    const showSwal = (payload) => {
      const defaults =  {
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        iconColor: '#24554C',
        background: '#5ABE86',
        timer: 3000,
        showClass: {
          popup: 'animate__animated animate__fadeInRight'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutRight'
        }
      }
      Swal.fire(Object.assign(defaults, payload))
    }
    provide('showSwal', showSwal)

    // 登入後， Header 點會員名出現的小選單
    let headerMenuOpened = ref(false)
    document.addEventListener('click', () => headerMenuOpened.value = false)
    const logOut = async () => {
      store.commit('setLoading', true);
      try {
        await axios.delete("/api/e_shop_infos/sign_out")
      } catch {
        await store.dispatch('logout')
      }
      await store.dispatch('logout')
      showSwal({title: '登出成功'});
      store.commit('setLoading', false);
    }

    // 播放音樂相關
    // ref for <audio>
    const isIOS = computed(() => {
      const ua = window.navigator.userAgent;
      return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    })
    const audio = ref(null)
    const audioSrc = computed(() => {
      if(currentSong.value) {
        if(currentSong.value.file_url.startsWith('http')) {
          return currentSong.value.file_url
        } else {
          return `${process.env.VUE_APP_API_URL}${currentSong.value.file_url}`
        }
      } else {
        return null
      }
    })
    // playlist load playlist object
    const playlist = ref({})
    // index for current playing song in the playlist
    const playlistCurrentIndex = ref(0)
    const songs = computed(() => playlist.value.musics || [])
    const currentSong = computed(() => playlistCurrentIndex.value < songs.value.length ? songs.value[playlistCurrentIndex.value] : null)
    // 正在播放
    const playing = ref(false)
    // 目前歌曲長度
    const duration = ref(0)
    const openAudioPlayer = (list, startIndex = 0) => {
      if(list.id === playlist.value.id) { return }
      playlist.value = list
      playlistCurrentIndex.value = startIndex
      audio.value.load()
    }
    const closeAudioPlayer = () => {
      audio.value.pause()
      playlist.value = {}
      playlistCurrentIndex.value = 0
    }
    const playAudio = () => {
      audio.value.play()
      playing.value = true
    }
    const pauseAudio = () => {
      audio.value.pause()
      playing.value = false
    }
    const changeSong = (index) => {
      if(isIOS.value) {
        pauseAudio()
      }
      playlistCurrentIndex.value = index
      audio.value.load()
    }
    const nextSong = () => {
      if(playlistCurrentIndex.value === songs.value.length - 1) {
        changeSong(0)
      } else {
        changeSong(playlistCurrentIndex.value + 1)
      }
    }
    const prevSong = () => {
      if(playlistCurrentIndex.value === 0) {
        changeSong(songs.value.length - 1)
      } else {
        changeSong(playlistCurrentIndex.value - 1)
      }
    }
    const playSong = (newPlaylist, index) => {
      if(newPlaylist.id !== playlist.value.id) {
        openAudioPlayer(newPlaylist, index)
      } else if (songs.value[index].id === currentSong.value.id) {
        playAudio()
      } else {
        changeSong(index)
      }
    }
    const seek = (percentage) => {
      audio.value.currentTime = percentage * duration.value
    }
    onMounted(() => {
      audio.value.addEventListener('loadedmetadata', () => {
        duration.value = audio.value.duration
      })
      if(!isIOS.value) {
        audio.value.addEventListener('canplay', () => {
          playAudio()
        })
      }
      audio.value.addEventListener('ended', () => {
        nextSong()
      })
    })

    provide('playlist', playlist)
    provide('playlistCurrentIndex', playlistCurrentIndex)
    provide('songs', songs)
    provide('currentSong', currentSong)
    provide('playing', playing)
    provide('duration', duration)
    provide('openAudioPlayer', openAudioPlayer)
    provide('closeAudioPlayer', closeAudioPlayer)
    provide('playAudio', playAudio)
    provide('pauseAudio', pauseAudio)
    provide('changeSong', changeSong)
    provide('nextSong', nextSong)
    provide('prevSong', prevSong)
    provide('playSong', playSong)
    provide('seek', seek)


    return {
      initializing,
      loading,
      isMobile,
      isMobileMenuOpen,
      headerMenuOpened,
      logOut,
      showLogin,
      showCart,
      showProductTags,
      showPromotions,
      anyModalShowed,
      closeAllModals,
      audio,
      audioSrc,
      playlist
    }
  }
}

</script>

<style lang="scss" scoped>
@import './css/variables';

#jingo-app {
  position: relative;
}

#modal-background {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(51, 51, 51, 0.1);
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

#sub-menu {
  position: fixed;
  top: 90px;
  right: 80px;
  z-index: 110;
  background: $background-color;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @include border-radius(5px);
  display: flex;
  flex-direction: column;

  a {
    margin: 0;
    padding: 20px;
    color: $dark-green;
    font-size: 0.875rem;
    border-bottom: 1px solid #E8E8E8;

    &:hover {
      color: $light-green;
    }
  }

  a:nth-last-child(1) {
    border-bottom: none;
  }
}

@media screen and (max-width: $mobile-max-width) {
  #jingo-app {
    margin-top: 60px;
  }
}
</style>
