<template>
  <div id="app-audio-player">
    <div id="audio-container">
      <template v-if="!isMobile">
        <div class="controls">
          <div
            title="上一首"
            class="btn prev"
            @click="prevSong"
          >
            <InlineSvg :src="require('../assets/player/prev.svg')" />
          </div>
          <div
            v-if="playing"
            title="暫停"
            class="btn play"
            @click="pauseAudio"
          >
            <InlineSvg :src="require('../assets/player/pause.svg')" />
          </div>
          <div
            v-else
            title="播放"
            class="btn"
            @click="playAudio"
          >
            <InlineSvg :src="require('../assets/player/play.svg')" />
          </div>
          <div
            title="下一首"
            class="btn next"
            @click="nextSong"
          >
            <InlineSvg :src="require('../assets/player/next.svg')" />
          </div>
        </div>
        <div class="song">
          <img :src="imgUrl(currentSong.cover_url)">
          <div class="titles">
            <router-link
              :to="currentSong.link"
              class="parent-title"
            >
              <span>{{ truncateString(currentSong.parent_title, 20) }}</span>
              <img src="../assets/player/little-caret.png">
            </router-link>
            <div class="title">
              {{ truncateString(currentSong.title, 30) }}
            </div>
          </div>
        </div>
        <div
          class="right open-playlist"
          @click="showPlaylist = !showPlaylist"
        >
          <img src="../assets/player/playlist.png">
          <span>播放清單</span>
        </div>
        <router-link
          :to="playlist.link"
          class="right open-playlist-link"
        >
          <img src="../assets/player/out.png">
          <span>前往介紹</span>
        </router-link>
        <div
          class="right close-player"
          @click="closeAudioPlayer"
        >
          <img src="../assets/player/X.png">
        </div>
      </template>
      <template v-else>
        <div class="controls">
          <div
            v-if="playing"
            title="暫停"
            class="btn play"
            @click="pauseAudio"
          >
            <InlineSvg :src="require('../assets/player/pause.svg')" />
          </div>
          <div
            v-else
            title="播放"
            class="btn"
            @click="playAudio"
          >
            <InlineSvg :src="require('../assets/player/play.svg')" />
          </div>
        </div>
        <div class="song">
          <div class="titles">
            <router-link
              :to="currentSong.link"
              class="parent-title"
            >
              <span>{{ truncateString(currentSong.parent_title, 6 ) }}</span>
              <img src="../assets/player/little-caret.png">
            </router-link>
            <div class="title">
              {{ truncateString(currentSong.title, 8) }}
            </div>
          </div>
        </div>
        <div
          class="right open-playlist"
          @click="showPlaylist = !showPlaylist"
        >
          <img src="../assets/player/playlist.png">
        </div>
        <div
          class="right close-player"
          @click="closeAudioPlayer"
        >
          <img src="../assets/player/X.png">
        </div>
      </template>
    </div>
    <div
      v-if="showPlaylist && isMobile"
      id="playlist-background"
      class="mobile"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInUp"
      leave-active-class="animate__animated animate__slideOutDown"
    >
      <div
        v-if="showPlaylist"
        id="audio-playlist"
      >
        <header>
          <router-link
            :to="playlist.link"
            class="info"
          >
            <span>{{ isMobile ? truncateString(playlist.name, 7) : playlist.name }}</span>
            <img src="../assets/player/out.png">
          </router-link>
          <div
            id="close-playlist"
            @click="showPlaylist = false"
          >
            <img src="../assets/player/esc.png">
          </div>
        </header>
        <main>
          <AppAudioPlayerSong
            v-for="(song, index) in songs"
            :key="song.id"
            :song="song"
            :index="index"
            :playing="playing && currentSong.id === song.id"
            :is-current-song="currentSong.id === song.id"
            :cursor-percentage="cursorPercentage"
            @pause="pauseAudio"
            @play="playSongInList(index)"
            @seek="seek"
          />
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, inject, toRefs, computed } from 'vue'
import InlineSvg from "vue-inline-svg"
import NumericalMixin from "../mixins/NumericalMixin"
import AppAudioPlayerSong from "../components/AppAudioPlayerSong"

export default {
  components: {
    InlineSvg,
    AppAudioPlayerSong
  },
  mixins: [NumericalMixin],
  props: {
    audio: {
      type: Object,
      required: true
    },
    playlist: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const imgUrl = inject('imgUrl')
    const { audio } = toRefs(props)

    const songs = inject('songs')
    const currentSong = inject('currentSong')
    const playing = inject('playing')
    const duration = inject('duration')
    const closeAudioPlayer = inject('closeAudioPlayer')
    const playAudio = inject('playAudio')
    const pauseAudio = inject('pauseAudio')
    const changeSong = inject('changeSong')
    const nextSong = inject('nextSong')
    const prevSong = inject('prevSong')
    const seek = inject('seek')

    const showPlaylist = ref(false)
    const cursorPosition = ref(0)
    const cursorPercentage = computed(() => {
      if(duration.value === 0) { return 0 }
      return cursorPosition.value / duration.value
    })
    const playlistCurrentIndex = inject('playlistCurrentIndex')
    const playSongInList = (index) => {
      if(index !== playlistCurrentIndex.value) {
        changeSong(index)
      } else {
        playAudio()
      }
    }

    onMounted(() => {
      audio.value.addEventListener('timeupdate', () => {
        cursorPosition.value = audio.value.currentTime
      })
    })

    const isMobile = inject('isMobile')

    return {
      imgUrl,
      songs,
      currentSong,
      playing,
      closeAudioPlayer,
      playAudio,
      pauseAudio,
      changeSong,
      nextSong,
      prevSong,
      seek,
      showPlaylist,
      cursorPercentage,
      playSongInList,
      isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#app-audio-player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 65px;
  z-index: 1000;
}

#audio-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 65px;
  z-index: 1100;
  background-color: $light-green;
}

.controls {
  display: flex;
  width: 278px;
  align-items: center;
  justify-content: space-around;

  .btn {
    cursor: pointer;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.song {
  display: flex;
  flex-grow: 1;

  img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    margin-right: 20px;
  }

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 65px;
  }

  .parent-title {
    display: flex;
    align-items: center;
    span {
      color: white;
      margin-right: 9px;
    }

    img {
      width: 6px;
      height: 10px;
    }
  }

  .title {
    font-size: 14px;
    color: $dark-green;
    line-height: 180%;
  }
}

.right {
  display: flex;
  align-items: center;
  height: 44px;
  padding-left: 30px;
  margin-left: 30px;
  color: white;
  border-left: 1px solid $border-color;
  cursor: pointer;

  img {
    width: 19px;
    height: 19px;
    margin-right: 12px;
  }
}
.close-player {
  margin-right: 56px;
}

#audio-playlist {
  position: fixed;
  bottom: 65px;
  left: 0;
  width: 100vw;
  background: rgba(36, 85, 76, 0.9);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  z-index: 1099;
  padding-bottom: 30px;
  header {
    padding: 30px 100px 20px 100px;
    border-bottom: 1px solid $border-color;
    a {
      display: flex;
      align-items: center;
      color: white;
      font-size: 19px;
      img {
        width: 20px;
        height: 20px;
        margin-left: 15px;
      }
    }

    #close-playlist {
      position: absolute;
      top: 30px;
      right: 100px;
      cursor: pointer;
      width: 34px;
      height: 34px;

      img {
        width: 35px;
        height: 35px;
      }
    }
  }
  main {
    padding: 0 100px;
  }
}

@media screen and (max-width: $mobile-max-width) {
  .controls {
    width: 64px;
  }

  .right {
    margin: 0;
    padding: 0;
    @include flex-center();

    img {
      margin: 0;
    }
  }
  .open-playlist {
    width: 54px;
  }

  .close-player {
    width: 44px;
  }

  #playlist-background {
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(51, 51, 51, 0.2);
  }

  #audio-playlist {
    height: 62.5vh;
    overflow-y: scroll;

    header {
      padding: 30px 15px;

      #close-playlist {
        right: 15px;
        width: 24px;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    main {
      padding: 0 15px;
    }
  }
}
</style>