<template>
  <div id="homepage">
    <HomePageBanner />
    <HomePageDonuts />
    <HomePageLatestArticle
      v-if="articles.length > 0"
      :article="articles[0]"
    />
    <HomePageLatestProducts />
    <HomePagePromotions v-if="promotionPagesNotHideOnHomePage.length > 0" />
    <HomePageFeaturedArticles
      v-if="featuredArticles.length > 0"
      :articles="featuredArticles"
    />
    <HomePageFeaturedProducts />
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        v-if="showHero"
        id="hero"
        @click="closeHero"
      >
        <div
          class="modal"
          @click.stop
        >
          <div
            class="close"
            @click="closeHero"
          >
            <BaseCross />
          </div>
          <header>親愛的金革會員你好！</header>
          <main>
            <p>歡迎來到金革全新的官方網站，未來我們會竭盡所能提供更多更好的音樂，以及更多元的音樂相關內容，務必留意金革官方網站的最新消息！</p>
            <ol>
              <li>如果您原本就是金革會員，請點擊右上角之「登入/註冊」，以原本官網設定之 E-mail 及密碼方可登入，原官網剩餘之購物金及 E-coupon 也在新官網轉換為 E-coupon 給您。若忘記原本設定的密碼，可以在登入時選擇「忘記密碼」，金革會將設定密碼連結發至您的信箱，即可重新設定密碼！</li>
              <li>如果輸入原本就有註冊過的信箱，前官網資料並沒有匯入，或是註冊上有問題的話，請寄信至金革客服信箱：service@jingo.com.tw，並告知我們您的姓名、電子信箱、電話，我們將儘速為您解決問題！</li>
              <li>新官網上線的會員權益變動事項可於頁面最下方之網站需知查看。</li>
            </ol>
          </main>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HomePageBanner from '../components/HomePageBanner.vue'
import HomePageDonuts from '../components/HomePageDonuts.vue'
import HomePageLatestArticle from '../components/HomePageLatestArticle.vue'
import HomePageLatestProducts from '../components/HomePageLatestProducts.vue'
import HomePagePromotions from '../components/HomePagePromotions.vue'
import HomePageFeaturedArticles from '../components/HomePageFeaturedArticles.vue'
import HomePageFeaturedProducts from '../components/HomePageFeaturedProducts.vue'
import { computed, inject, onMounted, ref } from 'vue'
import axios from 'axios'
import BaseCross from "../components/BaseCross.vue"

export default {
  components: {
    HomePageBanner,
    HomePageDonuts,
    HomePageLatestArticle,
    HomePageLatestProducts,
    HomePagePromotions,
    HomePageFeaturedArticles,
    HomePageFeaturedProducts,
    BaseCross
  },
  setup() {
    const articles = ref([])
    const featuredArticles = computed(() => articles.value.slice(0))
    onMounted(async () => {
      const {data} = await axios.get("/api/v1/pages/featured_articles")
      articles.value = data.articles
    })

    const promotionPages = inject('promotionPages')
    const promotionPagesNotHideOnHomePage = computed(() => promotionPages.value.filter(page => !page.hide_on_homepage))

    const showHero = ref(window.localStorage.getItem('heroShown') !== 'true')
    const closeHero = () => {
      showHero.value = false
      window.localStorage.setItem('heroShown', 'true')
    }

    return {
      articles,
      featuredArticles,
      promotionPagesNotHideOnHomePage,
      showHero,
      closeHero
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#homepage {
  margin-top: 35px;

  @media screen and (max-width: $mobile-max-width) {
    margin-top: 0;
  }
}

:deep(section) {
  margin-bottom: 120px;

  header {
    margin-bottom: 50px;
    position: relative;
    h3 {
      font-size: 32px;
      font-weight: 400;
    }
    .header-background {
      width: 110px;
      height: 110px;
      position: absolute;
      z-index: -1;
      top: -27px;
      left: -18px;
      border-radius: 100px;
      background-image: url('../assets/title_bg.png');
      background-size: cover;
    }
  }
}

#hero {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 500;
  @include flex-center();

  .modal {
    width: 700px;
    background: $background-color;
    position: relative;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 16px;
    cursor: pointer;
    z-index: 501;
  }
  header {
    border-bottom: 1px solid $border-color;
    padding: 16px 20px;
  }
  main {
    padding: 20px;
    p {
      line-height: 180%;
      margin-bottom: 10px;
      text-align: justify;
    }
    ol {
      padding-left: 20px;
      text-align: justify;
      line-height: 180%;
    }
  }
}

@media screen and (max-width: $mobile-max-width) {
  #homepage {
    margin-top: 0;
  }

  :deep(section) {
    padding: 0 15px;
    padding-top: 60px;
    background: url("../assets/home/bg-home-latest-products.png") no-repeat left top;
    margin-bottom: 50px;

    header {
      .header-background {
        border-radius: 0px;
        height: 46px;
        top: -5px;
        left: -9px;
      }
    }
  }

  #hero {
    .modal {
      width: 90vw;
    }
    main {
      height: 50vh;
      overflow-y: scroll;
    }
  }
}
</style>