<template>
  <div class="base-playlist">
    <h5>{{ playlist.name }}</h5>

    <div class="songs">
      <BasePlaylistSong
        v-for="(song, index) in songs"
        :key="song.id"
        :song="song"
        :index="index"
        @pause="pauseAudio"
        @play="playSong(playlist, index)"
      />
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import BasePlaylistSong from './BasePlaylistSong.vue'

export default {
  components: { BasePlaylistSong },
  props: {
    playlist: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const songs = computed(() => props.playlist.musics)
    const pauseAudio = inject('pauseAudio')
    const playSong = inject('playSong')

    return {
      songs,
      pauseAudio,
      playSong
    }
  }
}
</script>

<style lang="scss" scoped>
.base-playlist {
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 30px 0 30px 30px;
}

h5 {
  font-size: 13px;
  font-weight: normal;
  color: #808080;
  margin-bottom: 20px;
  word-break: break-all;
  padding-right: 15px;
}
</style>