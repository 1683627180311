<template>
  <div class="sub-orddet">
    <div class="cover">
      <img :src="imgUrl(product.cover_thumb)">
    </div>
    <div class="info">
      <div>
        <span class="name">{{ product.name }}</span>
        <span class="quantity desktop">x{{ orddet.quantity }}</span>
      </div>
      <div>
        <span class="price">{{ numberToCurrency(orddet.price) }}</span>
        <span class="quantity mobile">x{{ orddet.quantity }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import NumericalMixin from "../mixins/NumericalMixin"
export default {
  mixins: [NumericalMixin],
  props: {
    orddet: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const imgUrl = inject('imgUrl')
    const product = computed(() => props.orddet.product || {})
    const promotion = computed(() => props.orddet.promotion || {})

    return {
      imgUrl,
      product,
      promotion
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.sub-orddet {
  display: flex;
  margin-bottom: 10px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}
.cover {
  width: 90px;
  height: 90px;
  margin-right: 10px;
  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
  }
}

.info {
  width: calc(100% - 100px);
  height: 90px;
  display: flex;
  justify-content: space-between;
  .name {
    width: 230px;
  }
  .quantity {
    color: $light-gray;
    font-size: 0.875rem;
  }
  .price {
    color: $dark-orange;
    font-weight: 700;
  }

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: $mobile-max-width) {
  .info {
    flex-direction: column;

    div:nth-last-child(1) {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>