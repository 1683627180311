<template>
  <section id="latest-article">
    <div class="left">
      <img
        ref="articleImg"
        :src="imgUrl(article.image_url)"
      >
    </div>
    <div class="right">
      <div class="article-content">
        <div class="article-created-on">
          {{ article.created_on }}
        </div>
        <div class="article-title">
          {{ article.title }}
        </div>
        <div class="article-short-description">
          {{ article.short_description }}
        </div>
      </div>
      <router-link
        id="read-more-button"
        :to="{ name: 'Article', params: {slug: article.slug} }"
      >
        詳全文
      </router-link>
    </div>
  </section>
</template>

<script>
import { inject, nextTick, onMounted, onUnmounted, ref } from 'vue'

export default {
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  setup() {
    const imgUrl = inject('imgUrl')
    const articleImg = ref(null)
    const bannerRatio = inject('bannerRatio')
    const resizeImg = () => {
      articleImg.value.style.height = `${articleImg.value.clientWidth * bannerRatio}px`
    }
    onMounted(() => {
      nextTick(resizeImg)
      window.addEventListener('resize', resizeImg)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizeImg)
    })

    return {
      imgUrl,
      articleImg
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#latest-article {
  display: flex;
  justify-content: space-between;;
  align-items: center;
  position: relative;
}
.left {
  width: calc(100% - 580px);
  position: relative;
  margin-right: 60px;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  min-height: 300px;
}

.article-content {
  width: 520px;

  .article-created-on {
    color: rgba(51, 51, 51, 0.5);
  }

  .article-title {
    font-size: 21px;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .article-content {
    overflow: hidden;
    height: 100px;
    width: 100%;

    @media screen and (max-width: 768px) {
      height: 90px;
    }
  }
}
#read-more-button {
  border-radius: 100px;
  height: 80px;
  width: 80px;
  border: solid 1px $dark-green;
  color: $dark-green;
  @include flex-center();
  align-self: flex-end;

  &:hover {
    color: white;
    background: url('../assets/round_btn_hover.png') center center;
    background-size: cover;
  }
}

@media screen and (max-width: $mobile-max-width) {
  #latest-article {
    flex-direction: column;
    background-image: none;
    padding-top: 0;
  }
  .left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .article-content {
    width: 100%;
    margin-top: 0;
  }
  .article-title {
    font-size: 19px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .article-short-description {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
</style>