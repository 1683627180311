<template>
  <div id="login-forgot-password">
    <h2>忘記密碼</h2>
    <form @submit.stop.prevent="submit">
      <label for="email">請輸入註冊 E-mail</label><br>
      <input
        v-model="email"
        type="email"
      >
      <p>金革會將密碼設定連結發至你的信箱，請至信箱收信。</p>
      <div class="actions">
        <BaseCircleButton text="送出" />
      </div>
    </form>
  </div>
</template>

<script>
import BaseCircleButton from "./BaseCircleButton.vue"
import { inject, ref } from 'vue'
import axios from 'axios'

export default {
  components: { BaseCircleButton },
  emits: ['close-login'],
  setup(_, { emit }) {
    const email = ref('')
    const showSwal = inject('showSwal')
    const submit = async () => {
      try {
        await axios.post('/api/e_shop_infos/password', {
          api_e_shop_info: {
            email: email.value
          }
        })
        emit('close-login')
        showSwal({title: '請至信箱收重設密碼信'})
      } catch {
        showSwal({title: '失敗，請檢查 email 並重試'})
      }
    }

    return {
      email,
      submit
    }
  }
}
</script>

<style lang="scss" scoped>
#login-forgot-password {
  max-width: 600px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

label {
  font-size: 14px;
}

input {
  border: none;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  background: transparent;
  font-size: 1.2rem;
  line-height: 34px;
  padding-left: 5px;
  width: 100%;
  margin-bottom: 15px;
}

p {
  font-size: 14px;
  margin-bottom: 30px;
}

.actions {
  text-align: right;
}
</style>