<template>
  <button
    :disabled="disabled"
    class="circle-button"
  >
    <span>{{ text }}</span>
    <img src="../assets/round_btn_hover.png">
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../css/variables";
@import "../css/mixins";

.circle-button {
  width: 80px;
  height: 80px;
  background: transparent;
  border: 1px solid $dark-green;
  border-radius: 50%;
  color: $dark-green;
  cursor: pointer;
  position: relative;
  span {
    position: relative;
    @include transition(color 0.2s ease);
    z-index: 10;
  }

  img {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    @include transition(opacity 0.2s ease-in-out);
  }

  &:hover {
    span {
      color: white;
    }
    img {
      opacity: 1;
    }
  }

  &:disabled {
    cursor: not-allowed;
    border-color: $light-gray;
    span {
      color: $light-gray;
    }
    img {
      opacity: 0;
    }
  }
}
</style>