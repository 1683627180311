<template>
  <header>
    <router-link
      id="brand-logo"
      :to="{ name: 'Home' }"
      @click="closeMobileMenu"
    >
      <img
        src="../assets/JingoLogo.png"
        alt="回首頁"
      >
    </router-link>

    <div id="header-actions">
      <button
        id="cart"
        @click.stop.prevent="showCart"
      >
        <InlineSvg :src="require('../assets/cart.svg')" />
        <span
          v-if="cartQuantity > 0"
          class="cart-qty"
        >{{ cartQuantity }}</span>
      </button>
      <!-- 這是漢堡的按鈕 -->
      <div
        id="menu-hamburger"
        :class="{open: isMenuOpen}"
        @click.stop="toggleMenu"
      >
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </header>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import InlineSvg from "vue-inline-svg";

export default {
  components: { InlineSvg },
  setup() {
    const store = useStore()
    const isMenuOpen = computed(() => store.state.isMobileMenuOpen)
    const toggleMenu = () => store.commit('toggleMobileMenu')
    const closeMobileMenu = () => { store.commit('closeMobileMenu') }

    const cartQuantity = computed(() => store.getters.cartQuantity)
    const showCart = () => {
      if(!store.getters.isLoggedIn) {
        store.commit('openLogin')
        return
      }
      store.commit('openCart')
    }

    return {
      isMenuOpen,
      toggleMenu,
      closeMobileMenu,
      cartQuantity,
      showCart
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../css/variables';

header {
  position: fixed;
  top: 0;
  z-index: 100;
  background: $background-color;
  height: 60px;
  width: 100vw;
  padding-left: 13px;
  padding-right: 20px;
  border-top: 5px solid #24554C;
  box-shadow: 0px 4px 4px rgba(90, 190, 134, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;

}

#brand-logo {
  img {
    width: 150px;
  }
}

#header-actions {
  display: flex;
  align-items: center;

  #cart {
    margin-right: 15px;
    position: relative;
    background: transparent;
    border: none;

    .cart-qty {
      position: absolute;
      top: -15px;
      right: -15px;
      color: white;
      background: $dark-orange;
      font-size: 14px;
      @include flex-center();
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
  }
}

#menu-hamburger {
  width: 20px;
  height: 13px;
  position: relative;
  @include transform(rotate(0deg));
  @include transition(.5s ease-in-out);
  cursor: pointer;
}

#menu-hamburger span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: $dark-green;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#menu-hamburger span:nth-child(1) {
  top: 0px;
}

#menu-hamburger span:nth-child(2),#menu-hamburger span:nth-child(3) {
  top: 6px;
}

#menu-hamburger span:nth-child(4) {
  top: 12px;
}

#menu-hamburger.open span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

#menu-hamburger.open span:nth-child(2) {
  @include transform(rotate(45deg));
}

#menu-hamburger.open span:nth-child(3) {
  @include transform(rotate(-45deg));
}

#menu-hamburger.open span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}
</style>