import { createApp } from 'vue'
import App from '../App.vue'
import router from '../router'
import store from '../store'
require('../axios-config')
import "animate.css"
import "../css/style.scss"

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const sweetAlertOptions = {
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  icon: "success",
  iconColor: '#24554C',
  background: '#5ABE86',
  timer: 2000,
  showClass: {
    popup: 'animate__animated animate__fadeInRight'
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutRight'
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp(App)
  app.use(VueSweetalert2, sweetAlertOptions)
    .use(store).use(router)
    .mount('#app')
})