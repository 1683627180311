<template>
  <div id="home-page-banner">
    <swiper
      class="home-page-banner-swiper"
      :slides-per-view="1"
      :pagination="{clickable: true}"
      :autoplay="{delay: 7000}"
      @swiper="onSwiper"
      @active-index-change="bannerChanged"
    >
      <swiper-slide
        v-for="banner in banners"
        :key="banner.id"
      >
        <HomePageBannerSlide :banner="banner" />
      </swiper-slide>
    </swiper>
    <BaseMobilePlaylist
      v-if="isMobile && hasSongs"
      class="playlist"
      :playlist="currentBanner.playlist"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
SwiperCore.use([Pagination, Autoplay]);
import axios from 'axios'
import { ref, onMounted, inject, computed } from 'vue';
import HomePageBannerSlide from './HomePageBannerSlide.vue'
import BaseMobilePlaylist from './BaseMobilePlaylist.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    HomePageBannerSlide,
    BaseMobilePlaylist
  },
  setup() {
    const isMobile = inject('isMobile')
    const banners = ref([])
    const currentBanner = ref({})

    onMounted(async () => {
      const {data} = await axios.get('/api/v1/pages/banners')
      banners.value = data.pages_banners
      currentBanner.value = banners.value[0]
    })

    const swiper = ref(null)
    const onSwiper = (newSwiper) => {
      swiper.value = newSwiper
    }
    const bannerChanged = () => {
      currentBanner.value = banners.value[swiper.value.activeIndex]
    }

    const hasSongs = computed(() => {
      return currentBanner.value.id && currentBanner.value.playlist.musics.length > 0
    })

    return {
      isMobile,
      banners,
      currentBanner,
      bannerChanged,
      swiper,
      onSwiper,
      hasSongs
    }
  }
}
</script>

<style lang="scss" scoped>
#home-page-banner {
  position: relative;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  #home-page-banner {
    margin: 0;
    margin-bottom: 50px;
  }
  .banner {
    width: 100%;
  }
  .playlist {
    padding: 0 15px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
</style>
