<template>
  <div
    id="mobile-menu"
    @click="closeMenu"
  >
    <div
      v-if="!currentUser"
      class="menu-item"
      @click="openLogin"
    >
      <img
        class="button-img"
        src="../assets/icon-login.svg"
      >
      登入 / 註冊
    </div>
    <router-link
      v-else
      :to="{name: 'MemberCenter', params: {page: 'general'}}"
      class="menu-item"
    >
      Hi, {{ currentUser.name }}
    </router-link>

    <div
      id="mobile-search"
      class="menu-item"
      @click.stop="openSearch = !openSearch"
    >
      <div class="label">
        <img
          class="button-img"
          src="../assets/icon-search.svg"
        >
        搜尋商品
      </div>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInDown"
        leave-active-class="animate__animated animate__fadeOutUp"
      >
        <form
          v-if="openSearch"
          id="mobile-search-form"
          @click.stop
          @submit.prevent.stop="submitSearch"
        >
          <input
            v-model="searchTerms"
            type="search"
            placeholder="請輸入欲搜尋之關鍵字"
          >
          <button class="outline-button">
            搜尋
          </button>
        </form>
      </transition>
    </div>

    <div
      class="menu-item"
      @click.stop="showTags = true"
    >
      探索產品
      <img
        class="arrow"
        src="../assets/icon-mobile-menu-arrow.svg"
        alt="arrow"
      >
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInRight"
      leave-active-class="animate__animated animate__slideOutRight"
    >
      <div
        v-if="showTags"
        id="mobile-menu-tags"
      >
        <div
          class="menu-item"
          @click.stop="showTags = false"
        >
          <img
            class="reverse-arrow"
            src="../assets/icon-mobile-menu-arrow.svg"
            alt="arrow"
          >
          返回
        </div>
        <div
          class="menu-item"
          @click="toProductsByTag({})"
        >
          全部商品
        </div>
        <div
          v-for="tag in tags"
          :key="tag.id"
          class="menu-item"
          @click="toProductsByTag(tag)"
        >
          {{ tag.name }}
        </div>
      </div>
    </transition>
    <div
      v-if="promotionPages.length > 0"
      class="menu-item"
      @click.stop="showPromotions = true"
    >
      優惠活動
      <img
        class="arrow"
        src="../assets/icon-mobile-menu-arrow.svg"
        alt="arrow"
      >
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInRight"
      leave-active-class="animate__animated animate__slideOutRight"
    >
      <div
        v-if="showPromotions"
        id="mobile-menu-tags"
      >
        <div
          class="menu-item"
          @click.stop="showPromotions = false"
        >
          <img
            class="reverse-arrow"
            src="../assets/icon-mobile-menu-arrow.svg"
            alt="arrow"
          >
          返回
        </div>
        <router-link
          v-for="page in promotionPages"
          :key="page.id"
          class="menu-item"
          :to="{name: 'Promotion', params: {slug: page.slug}}"
        >
          {{ page.name }}
        </router-link>
      </div>
    </transition>
    <router-link
      to="/products?products_grid[formats][]=講堂票券"
      class="menu-item"
    >
      金革講堂
    </router-link>
    <router-link
      :to="{name: 'Articles'}"
      class="menu-item"
    >
      音樂觀點
    </router-link>
    <router-link
      :to="{ name: 'MusicPapers' }"
      class="menu-item"
    >
      音樂報
    </router-link>
    <router-link
      :to="{name: 'Public'}"
      class="menu-item"
    >
      企業公播
    </router-link>
    <div
      v-if="currentUser"
      class="menu-item"
      @click.stop="logOut"
    >
      登出
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore()
    const closeMenu = () => { store.commit('closeMobileMenu') }

    const currentUser = computed(() => store.getters.currentUser)
    const openLogin = () => { store.commit('toggleLogin') }

    const showSwal = inject('showSwal')
    const logOut = async () => {
      store.commit('setLoading', true);
      try {
        await axios.delete("/api/e_shop_infos/sign_out")
      } catch {
        await store.dispatch('logout')
      }
      await store.dispatch('logout')
      showSwal({
        title: '登出成功',
        icon: "success",
      });
      store.commit('setLoading', false);
      closeMenu()
    }

    let openSearch = ref(false)
    let searchTerms = ref('')
    const router = useRouter()
    const submitSearch = () => {
      router.push({
        name: 'Search',
        query: {
          q: searchTerms.value
        }
      })
    }

    let showTags = ref(false)
    const tags = inject('pagesTags')
    const toProductsByTag = inject('toProductsByTag')

    let showPromotions = ref(false)
    const promotionPages = inject('promotionPages')

    return {
      closeMenu,
      currentUser,
      openLogin,
      logOut,
      openSearch,
      searchTerms,
      submitSearch,
      showTags,
      tags,
      toProductsByTag,
      showPromotions,
      promotionPages
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#mobile-menu, #mobile-menu-tags {
  background: $background-color;
  width: 100vw;
  height: calc(100vh - #{$mobile-header-height});
  position: fixed;
  z-index: 2000;
  top: $mobile-header-height;
  left: 0;
  overflow-y: scroll;
}

:deep(.menu-item) {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: rgba(51, 51, 51, 0.2) 1px solid;
  padding: 20px 30px;
  font-size: 1rem;
  color: $dark-green;
  a {
    margin-top: -2px;
    color: $dark-green;
    line-height: 20px;
  }

  &:nth-child(1) {
    border-top: rgba(51, 51, 51, 0.2) 1px solid;
  }

  .button-img {
    margin-right: 10px;
  }

  .arrow {
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
  }

  .reverse-arrow {
    transform: rotate(180deg);
    margin-right: 10px;
  }
}

#mobile-search {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .label {
    display: flex;
    align-items: center;

  }

  form {
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;
  }

  input {
    margin-right: 10px;
    border: 1px solid rgba(51, 51, 51, 0.2);
    border-radius: 20px;
    height: 40px;
    padding-left: 20px;
    flex-grow: 2;
    background: transparent;
  }

  .outline-button {
    color: $dark-green;
    border-color: $dark-green;
    height: 40px;
  }
}
</style>