<template>
  <div
    :class="`donut-container dotted-background ${donut.colorName}`"
  >
    <div
      class="donut-progress piece-1"
      :class="donut.colorName"
    />
    <div
      class="donut-progress piece-2"
      :class="donut.colorName"
    />
    <div
      class="donut-progress piece-3"
      :class="donut.colorName"
    />
    <div
      class="donut-progress piece-4"
      :class="donut.colorName"
    />
    <div
      class="donut-progress piece-5"
      :class="donut.colorName"
    />
    <div class="donut-center">
      <div
        class="inner-circle"
        :class="donut.colorName"
      />
      <div
        class="donut-center-dot"
        :class="donut.colorName"
      />
    </div>
    <div :class="`title title-${donut.colorName}`">
      {{ donut.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    donut: {
      type: Object,
      required: true,
    }
  },
}
</script>
<style lang='scss' scoped>
$dark-green: #24554C;
$light-green: #5ABE86;
$dark-orange: #D75235;
$light-orange: #FA9E14;
$blue: #416BD7;
$gray: #9DCD15;

.donut-container {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  cursor: pointer;
  position: relative;

  &:hover {
    .piece-2 {
      transform: rotate(40deg);
    }
    .piece-3 {
      transform: rotate(224deg);
    }
    .piece-4 {
      transform: rotate(80deg);
    }
    .piece-5 {
      transform: rotate(162deg);
    }
  }

  @media screen and (max-width: 768px) {
    margin: 20px;
  }
}
// dotted background
.dotted-background {
  background-size: cover;

  &.dark-green {
    background-image: url('../assets/home/dark-green-dots.png');
  }
  &.light-green {
    background-image: url('../assets/home/light-green-dots.png');
  }
  &.dark-orange {
    background-image: url('../assets/home/dark-orange-dots.png');
  }
  &.light-orange {
    background-image: url('../assets/home/light-orange-dots.png');
  }
  &.blue {
    background-image: url('../assets/home/blue-dots.png');
  }
  &.gray {
    background-image: url('../assets/home/gray-dots.png');
  }
}

.donut-center {
  position: absolute;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;

  .inner-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    &.dark-green {
      background-color: rgb(146, 172, 163);
    }
    &.light-green {
      background-color: rgb(188, 226, 201);
    }
    &.dark-orange {
      background-color: rgb(246, 164, 150);
    }
    &.light-orange {
      background-color: rgb(255, 204, 129);
    }
    &.blue {
      background-color: rgb(161, 178, 233);
    }
    &.gray {
      background-color: rgb(202, 233, 128);
    }
  }
}

.donut-center-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  &.dark-green {
    background-color: $dark-green;
  }
  &.light-green {
    background-color: $light-green;
  }
  &.dark-orange {
    background-color: $dark-orange;
  }
  &.light-orange {
    background-color: $light-orange;
  }
  &.blue {
    background-color: $blue;
  }
  &.gray {
    background-color: $gray;
  }
}
.title {
  position: absolute;
  color: white;
  top: 50%;
  right: 7px;
  z-index: 3;
  transform: translateY(-50%);
}

.donut-progress {
  box-sizing: border-box;
  opacity: 1;
  position: relative;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 0 0 100px 0;
  transform-origin: 0% 0%;
  transform: rotate(313deg);
  transition-timing-function: ease-in-out;
  transition-duration: 2s;
}

.piece-2 {
  top: 0%;
}
.piece-3 {
  top: -50%;
}
.piece-4 {
  top: -100%;
}
.piece-5 {
  top: -150%;
}

// circle background color
.donut-progress {
  &.dark-green {
    background-color: $dark-green;
  }
  &.light-green {
    background-color: $light-green;
  }
  &.dark-orange {
    background-color: $dark-orange;
  }
  &.light-orange {
    background-color: $light-orange;
  }
  &.blue {
    background-color: $blue;
  }
  &.gray {
    background-color: $gray;
  }
}

@media screen and (max-width: 768px) {
  .donut-container {
    width: 150px;
    height: 150px;
    margin: 0;
    margin-bottom: 50px;
  }
}
</style>