<template>
  <footer>
    <div id="footer-content">
      <div id="footer-info">
        <img
          src="../assets/JingoLogoWhite.png"
          alt="JingoLogoWhite"
        >
        <h6>片刻感動・永恆記憶</h6>
        <p>
          金革國際唱片股份有限公司<br>
          地址：台灣 105 台北市松山區八德路三段30號12樓<br>
          電話：886-2-2579-5885<br>
          客服專線：(02)2579-5885<br>
          服務時間：週一至週五 9:00~18:00<br>
        </p>
      </div>

      <div id="footer-links">
        <div class="links">
          <div class="links-title">
            關於金革
          </div>
          <div class="link-list">
            <router-link :to="{ name: 'Home' }">
              首頁
            </router-link>
            <router-link :to="{ name: 'About' }">
              關於金革
            </router-link>
            <a
              href="https://www.sanfar.com.tw/zh-TW"
              target="_blank"
            >三發地產</a>
          </div>
        </div>
        <div class="links">
          <div class="links-title">
            專欄
          </div>
          <div class="link-list">
            <router-link :to="{ name: 'Articles' }">
              音樂觀點
            </router-link>
            <router-link :to="{ name: 'MusicPapers' }">
              音樂報
            </router-link>
          </div>
        </div>
        <div class="links">
          <div class="links-title">
            服務
          </div>
          <div class="link-list">
            <router-link :to="{ name: 'Products' }">
              探索產品
            </router-link>
            <router-link :to="{ name: 'Public' }">
              企業公播
            </router-link>
            <router-link :to="{name: 'Cooperate'}">
              企業合作
            </router-link>
          </div>
        </div>
        <div class="links">
          <div class="links-title">
            會員相關
          </div>
          <div class="link-list">
            <router-link :to="{ name: 'NewOrdsum' }">
              購物車
            </router-link>
            <router-link :to="{ name: 'MemberCenter', params: { page: 'overview' } }">
              會員中心
            </router-link>
          </div>
        </div>
        <div class="links">
          <div class="links-title">
            條款與聲明
          </div>
          <div class="link-list">
            <router-link :to="{ name: 'MemberFaq' }">
              會員需知
            </router-link>
            <router-link :to="{ name: 'OrdersFaq' }">
              購物需知
            </router-link>
            <router-link :to="{ name: 'TermsAndStatements' }">
              條款與聲明
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="footer-footer">
      <p id="copyright">
        Copyright © 2021 Jingo International Records Co., Ltd . All Rights Reserved.
      </p>
      <div id="social-links">
        <a
          href="https://www.facebook.com/TheJingoMusic/"
          target="_blank"
        >
          <img
            src="../assets/social-fb.png"
            alt="FB連結"
          >
        </a>
        <a
          href="https://www.instagram.com/jingorecords/"
          target="_blank"
        >
          <img
            src="../assets/social-ig.png"
            alt="IG連結"
          >
        </a>
        <a
          href="https://www.youtube.com/user/jingodigital"
          target="_blank"
        >
          <img
            src="../assets/social-yt.png"
            alt="Youtube連結"
          >
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "../css/variables";

footer {
  background-color: $dark-green;
  padding: 50px 100px 30px 100px;
}

#footer-content {
  display: flex;
  justify-content: space-between;
}

#footer-info {
  img {
    height: 60px;
    margin-bottom: 15px;
  }
  h6 {
    font-size: 1rem;
    font-weight: 400;
    color: $light-orange;
    margin-bottom: 25px;
  }
  p {
    font-size: 0.8rem;
    margin-bottom: 40px;
    color: white;
    line-height: 23.4px;
  }
}

#footer-links {
  display: flex;
  justify-content: flex-end;

  .links {
    margin-left: 21px;
  }

  .links-title {
    width: 105px;
    color: rgba(255, 255 ,255 , 0.7);
    line-height: 28.8px;
    padding-bottom: 7px;
    border-bottom: 1px solid rgba(255, 255 ,255 , 0.7);
    margin-bottom: 12px;
  }

  .link-list {
    display: flex;
    flex-direction: column;
    a {
      color: white;
      line-height: 28.8px;
    }
  }
}

#footer-footer {
  display: flex;
  justify-content: space-between;

  #copyright {
    font-size: 0.8rem;
    color: rgba(255, 255 ,255 , 0.7);
  }

  #social-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 106px;

    img {
      width: 22px;
      height: 22px;
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    padding-top: 36px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 125px;
  }

  #footer-content {
    flex-direction: column;
  }

  #footer-links {
    justify-content: space-between;
    flex-wrap: wrap;

    .links {
      margin-left: 0;
      margin-right: 25px;
      margin-bottom: 30px;
    }

    .links-title {
      width: 145px;
    }
  }

  #footer-footer {
    flex-direction: column;

    #copyright {
      margin-bottom: 1rem;
    }
  }
}
</style>