<template>
  <div class="base-quantity-adjuster">
    <div
      class="btn-minus"
      :class="{disabled: quantity <= min}"
      @click="$emit('remove-one', quantity - 1)"
    >
      <InlineSvg :src="require('../assets/btn-minus.svg')" />
    </div>
    <span>{{ quantity }}</span>
    <div
      class="btn-plus"
      @click="$emit('add-one', quantity + 1)"
    >
      <InlineSvg :src="require('../assets/btn-plus.svg')" />
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: { InlineSvg },
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 1,
    }
  },
  emits: ['add-one', 'remove-one'],
}
</script>

<style lang="scss" scoped>
@import "../css/variables";
.base-quantity-adjuster {
  display: flex;
  align-items: center;

  span {
    margin-left: 20px;
    margin-right: 20px;
    color: $dark-orange;
  }
}
:deep(.btn-minus), :deep(.btn-plus) {
  cursor: pointer;
  width: 26px;
  height: 26px;

  &:hover {
    svg {
      circle {
        stroke-opacity: 1;
      }
      line {
        stroke-opacity: 1;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    svg {
      circle {
        stroke-opacity: 0.2;
      }
      line {
        stroke-opacity: 0.2;
      }
    }
  }
}
</style>