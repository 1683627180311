<template>
  <div id="product-tags-modal">
    <div class="product-tags">
      <div
        class="product-tag"
        @click="toProducts({})"
      >
        全部商品
      </div>
      <div
        v-for="tag in list"
        :key="tag.id"
        class="product-tag"
        @click="toProducts(tag)"
      >
        {{ tag.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  setup() {
    const list = inject('pagesTags')
    const toProducts = inject('toProductsByTag')

    return {
      list,
      toProducts
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#product-tags-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 600px;
  height: 100vh;
  z-index: 2000;
  background-color: $background-color;
  padding-top: 50px;
  padding-left: 100px;
  overflow-y: scroll;
}

.product-tags {
  width: 180px;
}
.product-tag {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid $border-color;
  cursor: pointer;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:hover {
    background-color: $light-orange;
  }
}
</style>