<template>
  <div
    ref="bannerContainer"
    class="homepage-banner-banner"
    @click="$router.push(banner.href)"
  >
    <img
      class="banner-cover"
      :src="imgUrl(banner.image_url)"
      :alt="banner.title"
    >
    <div
      v-if="hasSongs && !isMobile"
      class="playlist"
      @click.stop
    >
      <BasePlaylist :playlist="banner.playlist" />
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import BasePlaylist from './BasePlaylist.vue'

export default {
  components: {BasePlaylist},
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const imgUrl = inject('imgUrl')
    const bannerContainer = ref(null)
    const bannerRatio = inject('bannerRatio')
    const resizeBanners = () => {
      bannerContainer.value.style.height = (bannerContainer.value.clientWidth * bannerRatio) + 'px'
    }

    onMounted(() => {
      resizeBanners()
      window.addEventListener('resize', resizeBanners)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizeBanners)
    })

    const isMobile = inject('isMobile')
    const hasSongs = computed(() => props.banner.playlist.musics.length > 0)

    return {
      imgUrl,
      bannerContainer,
      isMobile,
      hasSongs
    }
  }
}
</script>

<style lang="scss" scoped>
.homepage-banner-banner {
  width: 100%;
  cursor: pointer;
  position: relative;

  .banner-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.playlist {
  position: absolute;
  right: 0;
  top: 0;
  width: 500px;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
</style>