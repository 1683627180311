<template>
  <div
    id="login-modal"
    @click.stop
  >
    <BaseCross
      id="close-login"
      @click="closeLogin"
    />
    <main>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <LoginForgotPassword
          v-if="forgotPassword"
          @close-login="closeLogin"
        />
        <LoginLogin
          v-else-if="!registering"
          @to-register="registering = true"
          @close-login="closeLogin"
          @forgot-password="forgotPassword = true"
        />
        <LoginRegister
          v-else
          @to-login="registering = false"
          @close-login="closeLogin"
        />
      </transition>
    </main>
  </div>
</template>

<script>
import LoginLogin from './LoginLogin.vue'
import LoginRegister from './LoginRegister.vue'
import BaseCross from './BaseCross.vue';
import LoginForgotPassword from './LoginForgotPassword.vue'
import { ref, onMounted, nextTick, provide, inject } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios';

export default {
  components: {LoginLogin, LoginRegister, BaseCross, LoginForgotPassword},
  setup() {
    let registering = ref(false)

    let initializing = ref(true)
    onMounted(() => {
      initializing.value = false
    })

    const store = useStore()
    const closeLogin = () => {
      initializing.value = true
      nextTick(() => {
        store.commit('closeLogin')
      })
    }

    const forgotPassword = ref(false)

    const showSwal = inject('showSwal')
    const fbLogin = async () => {
      window.FB.login(async (response) => {
        store.commit('setLoading', true);
        if(response.authResponse) {
          try {
            const accessToken = response.authResponse.accessToken;
            const { data } = await axios.post("/api/e_shop_infos/sign_in", {
              api_e_shop_info: {
                provider: 'facebook',
                access_token: accessToken,
              }
            })
            await store.dispatch('login', data.token)
            showSwal({title: '登入成功'})
            closeLogin()
          } catch(error) {
            showSwal({
              title: `登入失敗：${error.response.data.error}`,
              icon: 'error'
            })
          }
        } else {
          showSwal({
            title: `登入失敗`,
            icon: 'error'
          })
        }
        store.commit('setLoading', false);
      }, {scope: 'email'})
    }
    provide('fbLogin', fbLogin)

    return {
      registering,
      initializing,
      closeLogin,
      forgotPassword
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

#login-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 1025px;
  height: 100vh;
  background: $light-orange;
  z-index: 2000;
  padding: 120px 0px 120px 100px;
  overflow-y: scroll;
  background-image: url("../assets/login/EC-login-bg.png");
  background-repeat: no-repeat;
  background-position: right top 100px;
  background-size: 350px;
}

#close-login {
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 100;
}

main {
  width: 100%;
  height: calc(100vh - 240px);
  position: relative;
}

:deep(.social-login) {
  h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 180%;
    margin-bottom: 15px;
  }
  button {
    cursor: pointer;
    color: white;
    padding: 5px 20px;
    height: 39px;
    background: #3A59A4;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 400;
    border: none;
  }
}

@media screen and (max-width: 768px) {
  #close-login {
    top: 20px;
    right: 20px;
  }

  #login-modal {
    width: 100vw;
    padding: 50px 20px 0 20px;
    overflow-y: scroll;
  }
}
</style>