<template>
  <section>
    <header>
      <div class="header-background" />
      <h3>最新商品</h3>
    </header>
    <div class="products">
      <template v-if="isMobile">
        <BaseProductTile
          v-for="product in products"
          :key="product.id"
          :product="product"
        />
      </template>
      <template v-else>
        <swiper
          :slides-per-view="5"
          :space-between="35"
          class="new-products-swiper"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="product in products"
            :key="product.id"
          >
            <BaseProductTile
              :product="product"
              class="product-tile"
            />
          </swiper-slide>
        </swiper>
        <div
          class="button-prev"
          @click="slideToPrev"
        />
        <div
          class="button-next"
          @click="slideToNext"
        />
      </template>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import SwiperCore, { Pagination } from 'swiper/core';
SwiperCore.use([Pagination]);
import { onMounted, ref, inject } from 'vue';
import axios from 'axios';
import BaseProductTile from "./BaseProductTile.vue"

export default {
  components: {
    Swiper,
    SwiperSlide,
    BaseProductTile,
  },
  setup() {
    const imgUrl = inject('imgUrl')
    const isMobile = inject('isMobile')

    const products = ref([]);
    onMounted(async () => {
      const {data} = await axios.get("/api/v1/pages/latest_products")
      products.value = data.products
    });

    const swiper = ref(null)
    const onSwiper = (newSwiper) => {
      swiper.value = newSwiper
    }
    const slideToPrev = () => {
      swiper.value.slidePrev()
    }
    const slideToNext = () => {
      swiper.value.slideNext()
    }

    return {
      imgUrl,
      isMobile,
      products,
      onSwiper,
      slideToPrev,
      slideToNext,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";
.header {
  font-size: 32px;
  text-align: left;
  margin-bottom: 50px;

  .header-background {
    width: 110px;
    height: 110px;
    position: relative;
    z-index: -1;
    top: 77px;
    left: -18px;
    border-radius: 100px;
    background-image: url('../assets/title_bg.png');
    background-size: cover;

      @media screen and (max-width: 768px) {
        border-radius: 0px;
        height: 46px;
        top: 43px;
        left: -9px;
      }
  }
}

.products {
  position: relative;
  width: 100%;
}
.product-tile {
  width: 100%;
  margin-bottom: 0;
}
.button-prev {
  position: absolute;
  background-image: url('../assets/brn-arrow-to-left.png');
  background-size: cover;
  height: 60px;
  width: 60px;
  top: 90px;
  left: 5px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    background-image: url('../assets/brn-arrow-to-left-hover.png');
  }

  &::after {
    content: '';
  }
}

.button-next {
  position: absolute;
  background-image: url('../assets/brn-arrow-to-right.png');
  background-size: cover;
  height: 60px;
  width: 60px;
  top: 90px;
  right: 5px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    background-image: url('../assets/brn-arrow-to-right-hover.png');
  }

  &::after {
    content: '';
  }
}

@media screen and (max-width: 768px) {
  .products {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}
</style>