<template>
  <div class="base-mobile-playlist">
    <select
      v-model="selected"
      @change="songSelected"
    >
      <option value="">
        {{ playlist.name }}
      </option>
      <option
        v-for="(song, index) in songs"
        :key="song.id"
        :value="index"
      >
        {{ song.title }}
      </option>
    </select>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref } from 'vue-demi'
export default {
  props: {
    playlist: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const songs = computed(() => props.playlist.musics)
    const playSong = inject('playSong')

    const selected = ref('')
    const songSelected = () => {
      if (selected.value.toString().length > 0) {
        playSong(props.playlist, selected.value)
      }
    }

    const currentSong = inject('currentSong')

    onMounted(() => {
      if(currentSong.value && songs.value.map(s => s.id).indexOf(currentSong.value.id) > -1) {
        selected.value = songs.value.map(s => s.id).indexOf(currentSong.value.id)
      }
    })

    return {
      songs,
      selected,
      songSelected,
      currentSong
    }
  }
}
</script>

<style lang="scss" scoped>
.base-mobile-playlist {
  position: relative;
}

select {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border: none;
  padding-left: 60px;
  font-size: 1rem;
}

.label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 0.875rem;
  color: #808080;
}
</style>