import { ref, computed, inject } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import qs from 'qs';

export default function () {
  const initializing = ref(true)
  // 抓全部購物車的商品
  const store = useStore()
  const products = ref([])
  const productIds = computed(() => store.getters.productIdsInCarts)
  const getProducts = async () => {
    let query = {
      per: productIds.value.length,
      products_grid: {
        id: productIds.value
      }
    }
    const {data} = await axios.get(`/api/v1/products?${qs.stringify(query, { arrayFormat: 'brackets' })}`)
    products.value = data.products
  }
  const allVirtualProducts = computed(() => products.value.every(x => x.category_list.includes('虛擬商品')))

  // 全部的優惠活動
  const allPromotions = inject('allPromotions')

  const calculating = ref(false)
  const mainCart = computed(() => store.state.cart.items)
  const tempCarts = computed(() => store.state.cart.tempCarts)
  const couponId = ref(null)
  // result holds calculated Ordsum
  const result = ref({})
  const cartItems = computed(() => {
    let h = {
      mainCart: [],
      tempCarts: [],
    }
    if(initializing.value) {
      return h
    }
    let orddets = [].concat(result.value.orddets)
    mainCart.value.forEach(item => {
      const product = products.value.find(pr => pr.id === item.product_id)
      let orddet = Object.assign(orddets.shift(), { product })
      orddet['gifts'] = []
      orddet['addons'] = []
      for(let [promotionId, productsInfo] of Object.entries(item.promotions)) {
        const promotion = allPromotions.value.find(pr => pr.id == promotionId)
        Object.keys(productsInfo).forEach(() => {
          let subItem = orddets.shift()
          Object.assign(subItem, {
            product: products.value.find(pr => pr.id === subItem.product_id),
            promotion
          })
          if(promotion.promotion_type === '贈品') {
            orddet['gifts'].push(subItem)
          } else {
            orddet['addons'].push(subItem)
          }
        })
      }
      h.mainCart.push(orddet)
    })
    tempCarts.value.forEach(cart => {
      const promotion = allPromotions.value.find(pr => pr.id == cart.promotion_id)
      let group = {
        promotion: promotion,
        orddets: []
      }
      cart.products.filter((productId, index) => index === cart.products.indexOf(productId)).forEach(() => {
        let item = orddets.shift()
        item['product'] = products.value.find(pr => pr.id === item.product_id)
        group.orddets.push(item)
      })
      h.tempCarts.push(group)
    })
    return h
  })
  // 移掉已下架或無庫存之商品
  const removeInvalidMainCartItems = () => {
    let list = []
    mainCart.value.forEach((item, index) => {
      let product = products.value.find(pr => pr.id === item.product_id)
      if (product.status != '已上架' || product.quantity <= 0) {
        list.push(product.name)
        store.commit('removeCartItem', index)
      }
    })
    if(list.length > 0) {
      alert(`${list.join(', ')}因已無庫存，已從購物車移除`)
    }
  }
  const removeInvalidTempCarts = () => {
    let list = []
    let cartsIndexToRemove = []
    tempCarts.value.forEach((tempCart, index) => {
      tempCart.products.forEach(product_id => {
        let product = products.value.find(pr => pr.id === product_id)
        if (product.status != '已上架' || product.quantity <= 0) {
          list.push(product.name)
          cartsIndexToRemove.push(index)
        }
      })
    })
    cartsIndexToRemove.filter((x, index, list) => list.indexOf(x) == index).forEach(i => {
      store.commit('removeTempCart', i)
    })
    if(list.length > 0) {
      alert(`${list.join(', ')}因已無庫存，已將對應優惠組合從購物車移除`)
    }
  }
  const calculate = async (ordsum) => {
    calculating.value = true
    if(initializing.value) {
      await getProducts()
      removeInvalidMainCartItems()
      removeInvalidTempCarts()

      if(allVirtualProducts.value) {
        ordsum.deliver_method = '自取'
      }
    }
    const {data} = await axios.post('/api/v1/ordsums/calculate', {
      ordsum,
      main_cart: mainCart.value,
      temp_carts: tempCarts.value,
      coupon_id: couponId.value
    })
    result.value = data.ordsum

    ordsum.subtotal = data.ordsum.subtotal
    ordsum.discount_value = data.ordsum.discount_value
    ordsum.deliver_fee = data.ordsum.deliver_fee
    ordsum.additional_discount_value = data.ordsum.additional_discount_value
    ordsum.total = data.ordsum.total
    ordsum.coupon = data.ordsum.coupon
    ordsum.orddets = data.ordsum.orddets
    ordsum.enabled_promotions = data.ordsum.enabled_promotions

    calculating.value = false
    initializing.value = false

    return {
      ordsum: data.ordsum
    }
  }

  return {
    calculating,
    calculate,
    cartItems,
    couponId,
    allVirtualProducts,
    products
  }
}