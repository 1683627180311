<template>
  <div id="login-register-form">
    <header>
      <h2>歡迎加入金革之友！</h2>
      <button
        v-if="registerStep < 3"
        class="outline-button"
        @click.prevent="$emit('to-login')"
      >
        已經註冊，立即登入！
      </button>
    </header>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <LoginRegister1
        v-if="registerStep === 1"
        :props-email="email"
        :props-password="password"
        :props-password-confirm="passwordConfirm"
        @data-change="step1DataChanged"
        @next-step="registerStep = 2"
      />
      <LoginRegister2
        v-else-if="registerStep === 2"
        :props-name="name"
        :props-mobile="mobile"
        :props-birthday="birthday"
        :props-agree-to-epaper="agreeToEpaper"
        :props-agree-to-be-called="agreeToBeCalled"
        :props-agree-to-music-paper="agreeToMusicPaper"
        @data-change="step2DataChanged"
        @prev-step="registerStep = 1"
        @next-step="registerStep = 3"
      />
      <LoginRegister3
        v-else-if="registerStep === 3"
        :genre-list="genreList"
        :format-list="formatList"
        :submitting="submitting"
        @data-change="step3DataChanged"
        @prev-step="registerStep = 2"
        @finish="register"
      />
    </transition>
  </div>
</template>

<script>
import LoginRegister1 from './LoginRegister1.vue'
import LoginRegister2 from './LoginRegister2.vue'
import LoginRegister3 from './LoginRegister3.vue'
import { inject, ref } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'

export default {
  components: { LoginRegister1, LoginRegister2, LoginRegister3 },
  emits: ['to-login', 'close-login'],
  setup(props, { emit }) {
    let registerStep = ref(1)

    let email = ref('')
    let password = ref('')
    let passwordConfirm = ref('')
    const step1DataChanged = (newEmail, newPassword, newPasswordConfirm) => {
      email.value = newEmail
      password.value = newPassword
      passwordConfirm.value = newPasswordConfirm
    }

    let name =  ref('')
    let mobile =  ref('')
    let birthday =  ref('')
    let agreeToEpaper = ref(false)
    let agreeToBeCalled = ref(false)
    let agreeToMusicPaper = ref(false)
    const step2DataChanged = (newName, newMobile, newBirthday, newAgreeToEpaper, newAgreeToBeCalled, newAgreeToMusicPaper) => {
      name.value = newName
      mobile.value = newMobile
      birthday.value = newBirthday
      agreeToEpaper = newAgreeToEpaper
      agreeToBeCalled = newAgreeToBeCalled
      agreeToMusicPaper = newAgreeToMusicPaper
    }

    let genreList = ref([])
    let formatList = ref([])
    const step3DataChanged = (newGenreList, newFormatList) => {
      genreList.value = newGenreList
      formatList.value = newFormatList
    }

    const store = useStore()
    const showSwal = inject('showSwal')
    const submitting = ref(false)
    const register = async () => {
      if(submitting.value) { return }

      submitting.value = true
      store.commit('setLoading', true);
      try {
        await axios.post("/api/e_shop_infos", {
          api_e_shop_info: {
            email: email.value,
            password: password.value,
            password_confirmation: passwordConfirm.value,
          },
          contact: {
            name: name.value,
            mobile: mobile.value,
            birthday: birthday.value,
            want_be_called: agreeToBeCalled.value,
            want_epaper: agreeToEpaper.value,
            want_music_paper: agreeToMusicPaper.value,
            genre_list: genreList.value,
            format_list: formatList.value,
          }
        })
        showSwal({
          title: '註冊成功，請至信箱收取驗證信',
        })

        // Meta Pixel tracking
        fbq('track', 'CompleteRegistration');

        emit('close-login')
      } catch(error) {
        showSwal({
          title: `註冊失敗：${error.response.data.message}`,
          icon: 'error',
        })
        submitting.value = false
      }
      store.commit('setLoading', false);
    }

    return {
      registerStep,
      email,
      password,
      passwordConfirm,
      step1DataChanged,
      name,
      mobile,
      birthday,
      step2DataChanged,
      agreeToEpaper,
      agreeToBeCalled,
      agreeToMusicPaper,
      genreList,
      formatList,
      step3DataChanged,
      register,
      submitting
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

:deep(.invalid) {
  color: $dark-orange;
}

#login-register-form {
  position: relative;
  z-index: 110;
}

header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;

  h2 {
    font-size: 2rem;
    margin-right: 20px;
  }
}


:deep(.register-form) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 130px;
  width: 609px;
  z-index: 110;

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    label {
      line-height: 25px;
      margin-bottom: 6px;
    }

    input {
      border: none;
      border-bottom: 1px solid rgba(33, 33, 33, 0.2);
      background: transparent;
      font-size: 1.2rem;
      line-height: 34px;
      padding-left: 5px;
      &::placeholder {
        color: rgba(33, 33, 33, 0.2);
        font-size: 0.8em;
      }
    }
  }

  .password-input {
    position: relative;
    input {
      width: 100%;
    }
    .show-password-btn {
      position: absolute;
      right: 20px;
      top: 5px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    align-items: start;
    flex-direction: column;
    h2 {
      line-height: 180%;
      margin-bottom: 10px;
    }
  }

  .register-form {
    width: 100%;
    margin-bottom: 50px;
  }
}
</style>